import React, { useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixButton from "../Components/PheonixButton";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import SupplierInfo from "./Supplierinfo";
import Creditterms from "./Creditterms";
import baseUrl from "../api";
import axios from "axios";
import { ADD_SUPPLIER } from '../graphql/query';
import { PheonixSnackbar } from "pheonixui";


interface AddSupplierProps {
  onSave: (data: any) => void;
  onClose: () => void;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}
interface SupplierData {
  supplierInfo: Record<string, any>;
  creditterms: Record<string, any>;
}
const AddSuppliers: React.FC<AddSupplierProps> = ({ onClose, setActiveStep, activeStep, onSave }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [error, setError] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState<"add" | "edit" | "delete" | "">("");
    const [snackbarTypecolor, setSnackbarTypecolor] = useState<"add" | "edit" | "delete" | "">("");
    const [alertmessage, setalertmessage] = useState("");
  
  const [supplierData, setSupplierData] = useState<SupplierData>({
    supplierInfo: {},
    creditterms: {},
  });
  const steps = ["Supplier Personal Info", "Credit Terms"];
  const handleSave = (data: Record<string, any>) => {
    setSupplierData((prev) => ({ ...prev, ...data }));
  };
  const handleSnackbarOpen = (type: "add" | "edit" | "delete") => {
    setSnackbarType(type);
    setSnackbarTypecolor(type);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
      setSnackbarType("");
      setSnackbarTypecolor("");
      setalertmessage("");
    }, 3000);
  };
  const validateSupplierDetails = (data: SupplierData) => {
    const requiredFields = [
      "supplier_name",
      "email",
      "country",
      "city",
      "shipping_address",
      "phone_number",
      "gst",
      "state",
      "billing_address",
    ];
    return requiredFields.reduce((errors, field) => {
      if (!data.supplierInfo[field] || data.supplierInfo[field] === "") {
        errors[field] = `${field.replace(/_/g, " ")} is required`;
      }
      return errors;
    }, {} as Record<string, string>);
  };
  const validateCreditTerms = (data: SupplierData) => {
    const requiredFields = ["credit_term_date", "payment_period"];
    return requiredFields.reduce((errors, field) => {
      if (!data.creditterms[field] || data.creditterms[field] === "") {
        errors[field] = `${field.replace(/_/g, " ")} is required`;
      }
      return errors;
    }, {} as Record<string, string>);
  };
  const handleFileChange = (files: File[]) => {
    setSupplierData((prev) => ({
      ...prev,
      supplierInfo: { ...prev.supplierInfo, documents: files },
    }));
  };
  const handleNext = async () => {
    let currentErrors: Record<string, string> = {};
    if (activeStep === 0) {
      currentErrors = validateSupplierDetails(supplierData);
    } else if (activeStep === 1) {
      currentErrors = validateCreditTerms(supplierData);
    }
    if (Object.keys(currentErrors).length > 0) {
      setFieldErrors(currentErrors);
      setError(Object.values(currentErrors).join(", "));
      return;
    }
    setError(null);
    setFieldErrors({});
    if (activeStep === steps.length - 1) {
      try {
        const variables = {
          ...supplierData.supplierInfo,
          ...supplierData.creditterms,
          documents: supplierData.supplierInfo.documents?.length > 0
            ? supplierData.supplierInfo.documents.map(() => null)
            : null,
        };

        if (supplierData.supplierInfo.documents?.length > 0) {
          const formData = new FormData();
          formData.append(
            "operations",
            JSON.stringify({
              query: ADD_SUPPLIER.loc?.source.body,
              variables,
            })
          );
          const map: Record<string, string[]> = {};
          supplierData.supplierInfo.documents.forEach((_: File, index: number) => {
            map[`${index}`] = [`variables.documents.${index}`];
          });
          formData.append("map", JSON.stringify(map));
          supplierData.supplierInfo.documents.forEach((file: File, index: number) => {
            formData.append(`${index}`, file);
          });
          const response = await axios.post(baseUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          const { addSupplier } = response.data.data;
          if (addSupplier?.statusCode === 201) {
            onSave(supplierData);
            onClose();
          } else {
            setError(addSupplier?.message || ERROR_MESSAGES.UNKNOW);
          }
        } else {
          const response = await axios.post(baseUrl, {
            query: ADD_SUPPLIER.loc?.source.body,
            variables,
          });

          const { addSupplier } = response.data.data;
          if (addSupplier?.statusCode === 201) {
            onSave(supplierData);
            onClose();
          } else {
            setError(addSupplier?.message || ERROR_MESSAGES.UNKNOW);
            setalertmessage(addSupplier?.message);
            handleSnackbarOpen("delete");
            setSnackbarOpen(true);
          }
        }
      } catch (err: any) {
        console.error(ERROR_MESSAGES.NETWORK_ERROR, err.message || err);
        setError(ERROR_MESSAGES.FAILED_SAVE);
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  const handleBack = () => setActiveStep((prev) => prev - 1);
  const handleDataChange = (key: keyof SupplierData, data: any) => {
    setSupplierData((prev) => ({
      ...prev,
      [key]: data,
    }));
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        paddingX: "16px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        border: "1px solid",
        borderColor: "rgba(224,224,224,1)",
        height: isSmallScreen ? "78vh" : isMediumScreen ? "80vh" : "70vh",
        overflowY: "auto",
        boxSizing: "border-box",
      }}
    >
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ fontFamily: "Lexend", display: "flex", justifyContent: "flex-start" }}
      >
        {TEXT_MESSAGES.ADD_TITLE}
      </Typography>
      <Box sx={{ flexGrow: 1, overflowY: "auto", display: "flex", flexDirection: "column" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    color: "#8C8C8C",
                    "&.Mui-active": { color: "black" },
                    "&.Mui-completed": { color: "black" },
                  },
                }}
                sx={{
                  fontFamily: "Lexend",
                  "& .MuiStepLabel-label": { fontFamily: "Lexend" },
               
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ flexGrow: 1, overflowY: "auto", display: "flex", flexDirection: "column" }}>
          {activeStep === 0 && (
            <SupplierInfo
              data={supplierData.supplierInfo || {}}
              onSave={(data) => handleDataChange("supplierInfo", data)}
              error={error}
              readOnly={false}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
              onFileChange={handleFileChange}
            />
          )}
          {activeStep === 1 && (
            <Creditterms
              data={supplierData.creditterms || {}}
              onSave={(data) => handleDataChange("creditterms", data)}
              error={error}
              readOnly={false}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
          )}
        </Box>

        <Box
          sx={{
            paddingX: "16px",
            paddingY: "10px",
            width: isSmallScreen ? "85%" : isMediumScreen ? "80%" : "90%",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "8px" }}>
            {activeStep > 0 && (
              <PheonixButton
                label={TEXT_MESSAGES.BACK_BUTTON}
                width={isSmallScreen ? "79px" : "90px"}
                backgroundColor={"white"}
                borderColor={"black"}
                border="1px solid"
                color={"secondary"}
                variant="outlined"
                onClick={handleBack}
                sx={{
                  color: "black",
                  minWidth: "79px",
                  minHeight: "33px",
                  transform: "none",
                  textTransform: "none",
                  "&:hover": { borderColor: "black", backgroundColor: "white" },
                }}
              />
            )}
            <PheonixButton
              label={activeStep === 1 ? TEXT_MESSAGES.SAVE_BUTTON : TEXT_MESSAGES.NEXT_BUTTON}
              variant="outlined"
              width={isSmallScreen ? "79px" : "90px"}
              onClick={handleNext}
              sx={{
                backgroundColor: "black",
                minWidth: "79px",
                minHeight: "33px",
                color: "white",
                transform: "none",
                textTransform: "none",
                "&:hover": { backgroundColor: "black" },
              }}
            />
            <PheonixSnackbar
                      vertical="top"
                      horizontal="right"
                      open={snackbarOpen}
                      onClose={() => {
                        setSnackbarOpen(false);
                      }}
                      message={alertmessage}
                      severity="success"
                      backgroundColor={
                        snackbarOpen
                          ? snackbarType === "add"
                            ? "#2E7D32"
                            : snackbarType === "edit"
                              ? "#EDF7ED"
                              : "#D32F2F29"
                          : "transparent"
                      }
                      color={
                        snackbarOpen
                          ? snackbarTypecolor === "add"
                            ? "#FFFFFF"
                            : snackbarTypecolor === "edit"
                              ? "#1E4620"
                              : "#663C00"
                          : "transparent"
                      }
                      sx={{
                        fontFamily: "Lexend",
                        fontWeight: 500,
                        fontSize: isSmallScreen ? "12px" : isMediumScreen ? "12px" : "14px",
                        mt: isSmallScreen ? 14 : isMediumScreen ? 14 : 12, 
                        height: isSmallScreen ? "34px" : isMediumScreen ? "36px" : "38px", 
                        paddingTop: isSmallScreen ? "4px" : "5px",
                        paddingBottom: isSmallScreen ? "6px" : "12px",
                        width:"auto",
                        maxWidth: isSmallScreen ? "78%" : isMediumScreen ? "400px" : "500px",
                        textAlign: "center",
                      }}
                    />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddSuppliers;