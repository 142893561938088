import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PopupModalProps from "../interfaces/PheonixPopupprops";
import { TEXT_MESSAGES } from "../const";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const PheonixPopup: React.FC<PopupModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#fff",
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          height: isSmallScreen ? "auto" : "257px",
          maxWidth: isSmallScreen ? "95vw" : isMediumScreen ? "75vw" : "419px",
          width: "auto",
          padding: "16px",
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: isSmallScreen ? "16px" : "18px",
          paddingTop: "10px",
          marginBottom: "8px",
        }}
      >
        <ErrorOutlineIcon
          sx={{
            width: isSmallScreen ? "40px" : "50px",
            height: isSmallScreen ? "40px" : "50px",
          }}
        />
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 0.87)",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 800,
            fontSize: isSmallScreen ? "14px" : "16px",
            fontFamily: "Inter",
          }}
        >
          {TEXT_MESSAGES.DISCARD}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: isSmallScreen ? "11px" : "12px",
            fontFamily: "Inter",
            marginTop: "7px",
          }}
        >
          {TEXT_MESSAGES.DISCARD_INFORMATION}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: isSmallScreen ? "10px" : "20px",
          flexWrap: "wrap",
          paddingBottom: isSmallScreen ? "10px" : "40px",
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            px: 4,
            width: isSmallScreen ? "80px" : "93px",
            height: "35px",
            borderColor: "#000000",
            color: "black",
            textTransform: "none",
            fontFamily: "Lexend",
            border: "1px solid",
            "&:hover": {
              borderColor: "#000000",
              color: "black",
              opacity: 0.9,
            },
          }}
        >
          {TEXT_MESSAGES.LEAVE}
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          sx={{
            height: "35px",
            textTransform: "none",
            bgcolor: "black",
            fontFamily: "Lexend",
            "&:hover": {
              bgcolor: "black",
              opacity: 0.9,
            },
          }}
        >
          {TEXT_MESSAGES.STAY_ON_PAGE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PheonixPopup;
