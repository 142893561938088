import * as React from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridColDef,
  GridPagination,
  GridToolbarQuickFilter,
  GridOverlay,
} from "@mui/x-data-grid";
import PheonixDataGridProps from "../interfaces/PheonixDataGridProps";
import {
  Box,
  Skeleton,
  useMediaQuery,
  GlobalStyles,
  colors,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import SkeletonLoader from "./PheonixSkeletonLoader";
import themestyle from "../theme";
import PheonixSearch from "../Components/PheonixSearch";

interface CustomToolbarProps {
  updatedColumns: GridColDef[];
  onSearchQueryChange: (query: string) => void;
  searchQuery: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  updatedColumns,
  onSearchQueryChange,
  searchQuery,
  onKeyDown,
}) => {
  const theme = useTheme();
  const isMobileL = useMediaQuery("(max-width: 425px) and (max-height: 608px)");


  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        padding: "8px",
        alignItems: "center",
        "@media (max-width: 425px) and (max-height: 608px)": {
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          fontFamily: "Lexend",
          display: "flex",
          flexWrap: "wrap",
          color: "#152726",
          gap: "8px",
          border: "0.5px solid #49955E",
          borderRadius: "4px",
          "@media (max-width: 425px) and (max-height: 608px)": {
            width: "100%",
            justifyContent: "space-between",
          },
        }}
      >
        <Box sx={{ display: "flex", gap: "8px" }}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </Box>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <GridToolbarDensitySelector />
          <GridToolbarExport
            csvOptions={{
              disableToolbarButton: false,
              fields: updatedColumns
                .filter((column) => column.field !== "actions")
                .map((column) => column.field),
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          "@media (min-width: 426px)": {
            width: "auto",
            marginLeft: "auto",
          },
        }}
      >
        <div style={{ marginTop: "2px",bottom: "8px" }}>
          {onSearchQueryChange && (
            <PheonixSearch value={searchQuery} onChange={onSearchQueryChange} />
          )}
        </div>
      </Box>
    </Box>
  );
};

const PheonixDataGrid: React.FC<PheonixDataGridProps> = ({
  columns,
  rows,
  pageSizeOptions,
  paginationModel,
  rowCount,
  onPaginationModelChange,
  paginationMode,
  width = "100%",
  height = "70vh",
  loading,
  customEmptyContent,
  showPagination = true,
  sx = {},
  onSearchQueryChange,
  searchQuery,
  onKeyDown,
  isShownAlert,
}) => {
  const theme = useTheme();
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  if (screenHeight > 1500) {
    height = "90vh";
  } else if (screenHeight > 1000) {
    height = "80vh";
  } else if (screenHeight === 642 && screenWidth === 768) {
    height = "70vh";
  } else if (screenHeight < 800 && screenHeight > 650) {
    height = "70vh";
  }

  const renderStatusCell = (params: any) => {
    if (!params || !params.row) return "";
    const { row } = params;
    return ` Success ${row.SuccessCount} / Failed ${row.FailedCount} `;
  };

  const updatedColumns = columns.map((column) => {
    if (column.field === "actions") {
      return {
        ...column,
        valueGetter: renderStatusCell,
      };
    }
    return column;
  });
  function CustomNoRowsOverlay() {
    if (rows.length === 0) {
      return (
        <GridOverlay>
          {searchQuery ? (
            <Box sx={{ mt: 1 }}>No match found</Box>
          ) : (
            <Box sx={{ mt: 1 }}>No rows</Box>
          )}
        </GridOverlay>
      );
    }
    return null;
  }

  return (
    <Box style={{ width, height }}>
      <GlobalStyles
        styles={{
          "::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "#AEF6B1",
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
        }}
      />
      <DataGrid
        rows={rows}
        columns={updatedColumns}
        paginationModel={paginationModel}
        rowCount={rowCount}
        paginationMode="server"
        disableRowSelectionOnClick
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={pageSizeOptions || [5, 10, 20, 30]}
        loading={false}
        autoHeight={false}
        slots={{
          toolbar: () => (
            <Box>
              {rows.length > 0 && isShownAlert && (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: isSmallScreen ? "center" : "flex-end",
                    flexWrap: "wrap",
                  }}
                >
                  <span
                    style={{
                      padding: "4px 8px",
                      gap: 8,
                      borderRadius: "4px",
                      backgroundColor: "#D2D2D2",
                      color: "#000000",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Lexend",
                      fontWeight: 300,
                      minWidth: "107px",
                      height: "fit-content",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Inventory2Icon sx={{ width: "10px", height: "12px" }} />
                    Out of Stock
                  </span>

                  <span
                    style={{
                      padding: "4px 8px",
                      borderRadius: "4px",
                      backgroundColor: "#FEBEBE",
                      color: "#000",
                      display: "inline-flex",
                      alignItems: "center",
                      fontFamily: "Lexend",
                      fontWeight: 300,
                      fontSize: "12px",
                      height: "fit-content",
                      minWidth: "75px",
                      whiteSpace: "nowrap",
                      gap: 8,
                    }}
                  >
                    <HourglassEmptyIcon
                      sx={{ color: "#E92727", width: "7px", height: "13px" }}
                    />
                    Expired
                  </span>

                  <span
                    style={{
                      height: "fit-content",
                      minWidth: "124px",
                      fontFamily: "Lexend",
                      fontWeight: 300,
                      fontSize: "12px",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      backgroundColor: "#FFA500C2",
                      color: "#000",
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <AssignmentLateOutlinedIcon
                      sx={{ color: "#FFFFFF", width: "11px", height: "12px" }}
                    />
                    Low stock alert
                  </span>
                </div>

              )}
              <Box>
                <CustomToolbar
                  updatedColumns={updatedColumns}
                  searchQuery={searchQuery}
                  onSearchQueryChange={onSearchQueryChange ?? (() => { })}
                  onKeyDown={onKeyDown}
                />
                {rows.length === 0 && !searchQuery && customEmptyContent}
              </Box>
            </Box>
          ),
          pagination: showPagination ? GridPagination : undefined,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              debounceMs: 250,
            },
          },
        }}
        sx={{
          fontFamily: "Lexend",
          borderRadius: "4px",
          border: "0.5px solid #49955E",
          padding: "20px",
          borderColor: "#5AD290",
          "& .MuiDataGrid-root": {
            minWidth: 300,
            overflowY: "auto",
          },
          ...sx,
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontSize: "14px",
            padding: "8px",
          },

          "& .MuiDataGrid-columnHeaders": {
            fontFamily: "Lexend",
            fontWeight: "bold !important",
            backgroundColor: "#f5f5f5",
            borderBottom: "0.5px solid #49955E",
            borderTop: "0.5px solid #49955E",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
          },
          "& .css-mh3zap": {
            fontWeight: 700,
          },
          "& .MuiTablePagination-selectLabel": {
            display: "block",
          },
          "& .MuiTablePagination-input": {
            display: "block",
          },
          "& .MuiTablePagination-select": {
            display: "flex",
            alignItems: "center",
            gap: "4px",
          },
          "& .css-1uyi3p7": {
            color: "#000000 !important",
            "& .MuiSvgIcon-root": {
              color: "#757575",
            },
            "& .MuiButtonBase-root": {
              color: "#000000 !important",
              fontWeight: "bold",
            },
          },
          "@media (max-width: 600px)": {
            "& .MuiDataGrid-root": {
              minWidth: "100%",
              overflowX: "scroll",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "12px",
              padding: "4px",
              whiteSpace: "normal",
              wordBreak: "break-word",

            },
            "& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel":
            {
              display: "block",
            },
            "& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-input": {
              display: "block",
            },
            "& .css-194a1fa-MuiSelect-select-MuiInputBase-input": {
              width: "50%",
            },
            "& .css-1cccqvr": {
              width: "50%",
            },
            "& .css-whi0l6 .MuiTablePagination-selectLabel": {
              display: "block",
            },
            "& .css-whi0l6 .MuiTablePagination-input": {
              display: "block",
            },
            "& .MuiTablePagination-input": {
              display: "block",
            },
          },
          "@media (max-width: 425px) and (max-height: 608px)": {
            "& .MuiDataGrid-root": {
              minWidth: "100%",
            },
            "& .MuiDataGrid-cell": {
              whiteSpace: "normal",
              overflow: "visible",
            },
            "& .MuiDataGrid-toolbarContainer": {
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            },
            "& .MuiTablePagination-selectLabel": {
              display: "block",
            },
            "& .MuiTablePagination-input": {
              display: "block",
            },
            "& .MuiTablePagination-select": {
              minHeight: "32px",
              fontSize: "0.875rem",
            },
          },
          ".makeStyles-caption-2[id]": {
            display: "none",
          },
          "@media (min-width:960px)": {
            ".makeStyles-caption-2[id]": {
              display: "block",
            },
          },
        }}
      />
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <SkeletonLoader />
        </Box>
      )}
    </Box>
  );
};

export default PheonixDataGrid;
