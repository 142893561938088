import { gql } from "@apollo/client";

export const GET_PRODUCT_QUERY = gql`
  query GetProductsperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getProductsperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      product {
        id
        product_code
        product_name
        product_description
        quantity
        unit
        gst_percentage
        isGST
        hsn_code
        date
        entrydate
        expirydate
        stock_alert
        stock_reference
        stock_notes
        purchase_price
        sales_price
        sales_price_gst
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts {
    getAllProducts {
      products {
        id
        product_code
        product_name
        product_description
        quantity
        unit
        gst_percentage
        isGST
        date
        entrydate
        expirydate
        hsn_code
        stock_alert
        stock_reference
        stock_notes
        purchase_price
        sales_price
        sales_price_gst
        product_id
        tax
        price
        amount
        item_price
      }
    }
  }
`;

export const GET_STAFF_QUERY = gql`
  query GetStaffperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getStaffperPage(page: $page, perPage: $perPage, searchQuery: $searchQuery) {
      totalPages
      totalCount
      staff {
        id
        staff_name
        staff_id
        role
        access_level {
          module
          permissions
        }
        date
      }
    }
  }
`;

export const GET_STOCK_QUERY = gql`
  query GetStocksperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getStocksperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      stock {
        id
        product_name
        quantity
        unit
        entrydate
        remarks
      }
    }
  }
`;
export const GET_SUPPLIER_QUERY = gql`
  query GetSuppliersperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getSuppliersperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      suppliers {
        id
        supplier_code
        supplier_name
        email
        country
        city
        shipping_address
        documents
        phone_number
        gst
        state
        alter_phonenumber
        billing_address
        credit_term_date
        open_balance
        grace_period
        credit_limit
        payment_period
        notes
        date
      }
    }
  }
`;
export const GET_CUSTOMER_QUERY = gql`
  query GetCustomersPerPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getCustomersPerPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      customers {
        id
        customer_code
        customer_name
        email
        country
        city
        shipping_address
        documents
        phone_number
        gst
        state
        alter_phonenumber
        billing_address
        credit_term_date
        open_balance
        grace_period
        credit_limit
        payment_period
        notes
        date
      }
    }
  }
`;
export const GET_ALL_SUPPLIERS = gql`
  query GetAllSuppliers {
    getAllSuppliers {
      supplier {
        id
        supplier_name
      }
    }
  }
`;

export const ADD_PRODUCT_MUTATION = gql`
  mutation AddProduct(
    $product_name: String
    $product_description: String
    $quantity: Int
    $unit: String
    $gst_percentage: String
    $isGST:Boolean
    $entrydate: Date
    $expirydate: Date
    $hsn_code: Int
    $stock_alert: Int
    $stock_reference: [Upload]
    $stock_notes: String
    $purchase_price: Float
    $sales_price: Float
    $sales_price_gst: Float
    $isstock: Boolean
  ) {
    addProduct(
      product_name: $product_name
      product_description: $product_description
      quantity: $quantity
      unit: $unit
      gst_percentage: $gst_percentage
      isGST:$isGST
      entrydate: $entrydate
      expirydate: $expirydate
      hsn_code: $hsn_code
      stock_alert: $stock_alert
      stock_reference: $stock_reference
      stock_notes: $stock_notes
      purchase_price: $purchase_price
      sales_price: $sales_price
      sales_price_gst: $sales_price_gst
      isstock: $isstock
    ) {
      statusCode
      message
      product {
        id
        product_name
      }
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation EditProduct(
    $id: ID!
    $product_name: String
    $product_description: String
    $quantity: Int
    $unit: String
    $gst_percentage: String
    $isGST:Boolean
    $entrydate: Date
    $expirydate: Date
    $hsn_code: Int
    $stock_alert: Int
    $stock_reference: [Upload]
    $stock_notes: String
    $purchase_price: Float
    $sales_price: Float
    $sales_price_gst: Float
    $isstock: Boolean
  ) {
    editProduct(
      id: $id
      product_name: $product_name
      product_description: $product_description
      quantity: $quantity
      unit: $unit
      gst_percentage: $gst_percentage
      isGST:$isGST
      entrydate: $entrydate
      expirydate: $expirydate
      hsn_code: $hsn_code
      stock_alert: $stock_alert
      stock_reference: $stock_reference
      stock_notes: $stock_notes
      purchase_price: $purchase_price
      sales_price: $sales_price
      sales_price_gst: $sales_price_gst
      isstock: $isstock
    ) {
      statusCode
      message
      product {
        id
        product_name
      }
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      statusCode
      message
    }
  }
`;

export const UPLOAD_CSV = gql`
  mutation uploadCSV($file: Upload!) {
    uploadCSV(file: $file) {
      success
      message
    }
  }
`;
export const PDF_UPLOAD = gql`
  mutation UploadStockReferencePDF($productId: ID!, $file: Upload!) {
    uploadStockReferencePDF(productId: $productId, file: $file) {
      success
      message
      fileUrl
    }
  }
`;

export const ADD_STOCK_MUTATION = gql`
  mutation AddStock(
    $product_name: String
    $quantity: Int
    $unit: String
    $entrydate: Date
    $remarks: String
  ) {
    addStock(
      product_name: $product_name
      quantity: $quantity
      unit: $unit
      entrydate: $entrydate
      remarks: $remarks
    ) {
      statusCode
      message
      stock {
        id
        product_name
        quantity
        unit
        entrydate
        remarks
      }
    }
  }
`;
export const EDIT_STOCK_MUTATION = gql`
  mutation EditStock(
    $id: ID!
    $product_name: String
    $quantity: Int
    $unit: String
    $entrydate: Date
    $remarks: String
  ) {
    editStock(
      id: $id
      product_name: $product_name
      quantity: $quantity
      unit: $unit
      entrydate: $entrydate
      remarks: $remarks
    ) {
      statusCode
      message
      stock {
        id
        product_name
        quantity
        unit
        entrydate
        remarks
      }
    }
  }
`;
export const DELETE_STOCK = gql`
  mutation DeleteStock($id: ID!) {
    deleteStock(id: $id) {
      statusCode
      message
    }
  }
`;

export const UPLOAD_STOCK_CSV = gql`
  mutation uploadStockCSV($file: Upload!) {
    uploadStockCSV(file: $file) {
      success
      message
    }
  }
`;

export const ADD_STAFF_MUTATION = gql`
  mutation AddStaff(
    $staff_id: String
    $staff_name: String
    $role: String
    $access_level: [AccessLevelInput]
    $date: Date
  ) {
    addStaff(
      staff_id: $staff_id
      staff_name: $staff_name
      role: $role
      access_level: $access_level
      date: $date
    ) {
      statusCode
      message
      staff {
        id
        staff_id
        staff_name
        role
        access_level {
          module
          permissions
        }
        date
      }
    }
  }
`;

export const EDIT_STAFF_MUTATION = gql`
  mutation EditStaff(
    $id: ID!
    $staff_name: String
    $staff_id: String
    $role: String
    $access_level: [AccessLevelInput]
    $date: Date
  ) {
    editStaff(
      id: $id
      staff_name: $staff_name
      staff_id: $staff_id
      role: $role
      access_level: $access_level
      date: $date
    ) {
      statusCode
      message
      staff {
        id
        staff_name
        staff_id
        role
        access_level {
          module
          permissions
        }
        date
      }
    }
  }
`;
export const DELETE_STAFF = gql`
  mutation DeleteStaff($id: ID!) {
    deleteStaff(id: $id) {
      statusCode
      message
    }
  }
`;

export const ADD_SUPPLIER = gql`
  mutation AddSupplier(
    $supplier_name: String
    $email: String
    $country: String
    $city: String
    $shipping_address: String
    $documents: [Upload]
    $phone_number: String
    $gst: String
    $state: String
    $alter_phonenumber: String
    $billing_address: String
    $credit_term_date: Date
    $open_balance: Float
    $grace_period: Float
    $credit_limit: Float
    $payment_period: Float
    $notes: String
  ) {
    addSupplier(
      supplier_name: $supplier_name
      email: $email
      country: $country
      city: $city
      shipping_address: $shipping_address
      documents: $documents
      phone_number: $phone_number
      gst: $gst
      state: $state
      alter_phonenumber: $alter_phonenumber
      billing_address: $billing_address
      credit_term_date: $credit_term_date
      open_balance: $open_balance
      grace_period: $grace_period
      credit_limit: $credit_limit
      payment_period: $payment_period
      notes: $notes
    ) {
      statusCode
      message
      supplier {
        id
        supplier_name
      }
    }
  }
`;

export const EDIT_SUPPLIER = gql`
  mutation EditSupplier(
    $id: ID!
    $supplier_name: String
    $email: String
    $country: String
    $city: String
    $shipping_address: String
    $documents: [Upload]
    $phone_number: String
    $gst: String
    $state: String
    $alter_phonenumber: String
    $billing_address: String
    $credit_term_date: Date
    $open_balance: Float
    $grace_period: Float
    $credit_limit: Float
    $payment_period: Float
    $notes: String
  ) {
    editSupplier(
      id: $id
      supplier_name: $supplier_name
      email: $email
      country: $country
      city: $city
      shipping_address: $shipping_address
      documents: $documents
      phone_number: $phone_number
      gst: $gst
      state: $state
      alter_phonenumber: $alter_phonenumber
      billing_address: $billing_address
      credit_term_date: $credit_term_date
      open_balance: $open_balance
      grace_period: $grace_period
      credit_limit: $credit_limit
      payment_period: $payment_period
      notes: $notes
    ) {
      statusCode
      message
      supplier {
        id
        supplier_name
      }
    }
  }
`;

export const DELETE_SUPPLIER = gql`
  mutation DeleteSupplier($id: ID!) {
    deleteSupplier(id: $id) {
      statusCode
      message
    }
  }
`;

export const ADD_CUSTOMER_MUTATION = gql`
  mutation AddCustomer(
    $customer_name: String
    $email: String
    $country: String
    $city: String
    $shipping_address: String
    $documents: [Upload]
    $phone_number: String
    $gst: String
    $state: String
    $alter_phonenumber: String
    $billing_address: String
    $credit_term_date: Date
    $open_balance: Float
    $grace_period: Float
    $credit_limit: Float
    $payment_period: Float
    $notes: String
  ) {
    addCustomer(
      customer_name: $customer_name
      email: $email
      country: $country
      city: $city
      shipping_address: $shipping_address
      documents: $documents
      phone_number: $phone_number
      gst: $gst
      state: $state
      alter_phonenumber: $alter_phonenumber
      billing_address: $billing_address
      credit_term_date: $credit_term_date
      open_balance: $open_balance
      grace_period: $grace_period
      credit_limit: $credit_limit
      payment_period: $payment_period
      notes: $notes
    ) {
      statusCode
      message
      customer {
        id
        customer_name
      }
    }
  }
`;

export const EDIT_CUSTOMER_MUTATION = gql`
  mutation EditCustomer(
    $id: ID!
    $customer_name: String
    $email: String
    $country: String
    $city: String
    $shipping_address: String
    $documents: [Upload]
    $phone_number: String
    $gst: String
    $state: String
    $alter_phonenumber: String
    $billing_address: String
    $credit_term_date: Date
    $open_balance: Float
    $grace_period: Float
    $credit_limit: Float
    $payment_period: Float
    $notes: String
  ) {
    editCustomer(
      id: $id
      customer_name: $customer_name
      email: $email
      country: $country
      city: $city
      shipping_address: $shipping_address
      documents: $documents
      phone_number: $phone_number
      gst: $gst
      state: $state
      alter_phonenumber: $alter_phonenumber
      billing_address: $billing_address
      credit_term_date: $credit_term_date
      open_balance: $open_balance
      grace_period: $grace_period
      credit_limit: $credit_limit
      payment_period: $payment_period
      notes: $notes
    ) {
      statusCode
      message
      customer {
        id
        customer_name
      }
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      statusCode
      message
    }
  }
`;

export const GET_ALL_CUSTOMER = gql`
  query GetAllCustomers {
    getAllCustomers {
      customers {
        id
        customer_name
        billing_address
        gst
      }
    }
  }
`;

export const GET_QUOTATION_QUERY = gql`
  query GetQuotationsPerPage(
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getQuotationsPerPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      statusCode
      totalPages
      totalCount
      quotations {
        id
        quotation_code
        customer_name
        address
        currency
        status
        date
        expiry_date
        quotation_notes
        termsandconditions
        customernotes
        cgst
        sgst
        gstamount
        totalamountwithouttax
        grandtotal
        roundoff
        productdata {
          product_name
          product_description
          quantity
          unit
          gst_percentage
          hsn_code
          sales_price
          sales_rate
          sales_price_gst
        }
      }
    }
  }
`;

export const GET_LAST_QUOTATION = gql`
  query GetLastQuotation {
    getLastQuotation {
      quotation_code
    }
  }
`;
export const CHECK_QUOTATION_EXISTS_QUERY = gql`
  query CheckQuotationExists($quotation_code: String!) {
    checkQuotationExists(quotation_code: $quotation_code)
  }
`;

export const ADD_QUOTATION_MUTATION = gql`
  mutation AddQuotation(
    $quotation_code: String
    $customer_name: String!
    $currency: String!
    $status: String!
    $date: String
    $expiry_date: String!
    $quotation_notes: String
    $productdata: [ProductDataInput!]!
    $termsandconditions: String
    $customernotes: String
    $cgst: Float
    $sgst: Float
    $gstamount: Float
    $totalamountwithouttax: Float
    $grandtotal: Float
    $address:String
    $roundoff:Float
  ) {
    addQuotation(
      quotation_code: $quotation_code
      customer_name: $customer_name
      currency: $currency
      status: $status
      date: $date
      expiry_date: $expiry_date
      quotation_notes: $quotation_notes
      productdata: $productdata
      termsandconditions: $termsandconditions
      customernotes: $customernotes
      cgst: $cgst
      sgst: $sgst
      gstamount: $gstamount
      totalamountwithouttax: $totalamountwithouttax
      grandtotal: $grandtotal
      address:$address
      roundoff:$roundoff
      
    ) {
      statusCode
      message
      quotation {
        id
        quotation_code
        customer_name
      }
    }
  }
`;

export const EDIT_QUOTATION_MUTATION = gql`
  mutation EditQuotation(
    $id: ID!
    $quotation_code: String
    $customer_name: String!
    $currency: String!
    $status: String!
    $date: String!
    $expiry_date: String!
    $quotation_notes: String
    $productdata: [ProductDataInput!]!
    $termsandconditions: String
    $customernotes: String
    $cgst: Float
    $sgst: Float
    $gstamount: Float
    $totalamountwithouttax: Float
    $grandtotal: Float
    $address:String
    $roundoff:Float
  ) {
    editQuotation(
      id: $id
      quotation_code: $quotation_code
      customer_name: $customer_name
      currency: $currency
      status: $status
      date: $date
      expiry_date: $expiry_date
      quotation_notes: $quotation_notes
      productdata: $productdata
      termsandconditions: $termsandconditions
      customernotes: $customernotes
      address:$address
      cgst: $cgst
      sgst: $sgst
      gstamount: $gstamount
      totalamountwithouttax: $totalamountwithouttax
      grandtotal: $grandtotal
      roundoff:$roundoff
    ) {
      statusCode
      message
      quotation {
        id
        quotation_code
        customer_name
      }
    }
  }
`;

export const DELETE_QUOTATION = gql`
  mutation DeleteQuotation($id: ID!) {
    deleteQuotation(id: $id) {
      statusCode
      message
    }
  }
`;

export const RESEND_EMAIL_MUTATION = gql`
  mutation ResendEmail(
    $serial_no: Int
    $id: ID
    $quotation_code: String
    $customer_name: String
    $currency:String
    $status: String
    $date: String
    $expiry_date: String
    $cgst:Float
    $sgst: Float
    $totalamountwithouttax: Float
    $grandtotal: Float
    $productdata: [ProductDataInput]
  ) {
    resendEmail(
      serial_no: $serial_no
      id: $id
      quotation_code: $quotation_code
      customer_name: $customer_name
      currency:$currency
      status: $status
      date: $date
      expiry_date: $expiry_date
      cgst:$cgst
      sgst:$sgst
      totalamountwithouttax:$totalamountwithouttax
    grandtotal:$grandtotal      
    productdata: $productdata
    ) {
      message
      quotation {
        id
        quotation_code
        status
        date
        expiry_date
      }
    }
  }
`;
