import React, { useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { TEXT_MESSAGES } from "../const";
import PheonixButton from "../Components/PheonixButton";

const Home: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        width: "80%",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        style={{
          fontWeight: 400,
          fontFamily: "Roboto",
          fontSize: isSmallScreen ? "32px" : "48px",
          color: "black",
          textAlign: "center",
        }}
      >
        {TEXT_MESSAGES.Progresstext}
      </Typography>
    </div>
  );
};
export default Home;
