import React, { useState,useEffect } from 'react';
import Sidebar from './Sidebar';
import AppBar from '../Components/PheonixAppBar';
import LayoutProps from "../interfaces/LayoutProps";
import PheonixFooter from '../Components/PheonixFooter';

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(() => {
    const savedIsOpen = localStorage.getItem('issidebarOpen');
    return savedIsOpen ? JSON.parse(savedIsOpen) : false;
  });  
  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    localStorage.setItem('issidebarOpen', JSON.stringify(isOpen));
  }, [isOpen]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar title={title} isSidebarOpen={isOpen} />
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <Sidebar isOpen={isOpen} onToggle={handleToggleSidebar} />
        <div style={{ flexGrow: 1, overflow: 'auto'}}>
          {children}
        </div>
      </div>
      <div>
        <PheonixFooter />
      </div>
    </div>
  );
};

export default Layout;
