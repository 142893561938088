import type React from "react";
import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  LinearProgress,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useMutation } from "@apollo/client";
import { UPLOAD_CSV } from "../graphql/query";
import baseUrl from "../api";
import axios from "axios";
import { TEXT_MESSAGES } from "../const";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

interface ImportBulkFileProps {
  isOpen: boolean;
  onClose: () => void;
}

const ImportBulkFile: React.FC<ImportBulkFileProps> = ({ isOpen, onClose }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [error, setError] = useState<string>("");

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile?.type === "text/csv") {
      setFile(droppedFile);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      if (!selectedFile.name.toLowerCase().endsWith(".csv")) {
        setError("Invalid file type. Please upload a CSV file.");
        setFile(null);
        e.target.value = "";
        return;
      }
      setError("");
      setFile(selectedFile);
    }
  };

  const handleDownloadSample = () => {
    const sampleData = `Product,Product description,Opening Stock,Unit,HSN Code,Entry date,Expiry date,Stock Alert,Notes,Purchase price,Sales price,GST Percentage,Sales GST price,GST
    \nProduct 1,productdescription,100,Bags,23,2025-03-31,2025-04-07,10,productnotes,749.64,809.43,5%,974.35,true
    `;
    const blob = new Blob([sampleData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample-product-import.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const simulateProgress = () => {
    let progress = 0;
    const timer = setInterval(() => {
      progress += Math.random() * 10;
      setUploadProgress(Math.min(progress, 100));
      if (progress >= 100) {
        clearInterval(timer);
      }
    }, 500);
    return timer;
  };

  const handleSave = async () => {
    if (file) {
      setIsUploading(true);
      setUploadProgress(0);

    
      const timer = simulateProgress();

      try {
        const formData = new FormData();
        formData.append(
          "operations",
          JSON.stringify({
            query: UPLOAD_CSV.loc?.source.body,
            variables: { file: null },
          })
        );
        formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
        formData.append("0", file);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios.post(baseUrl, formData, config);

        const { success, message } = response.data.data.uploadCSV;

        if (success) {
          clearInterval(timer);
          setUploadProgress(100);
          setUploadSuccess(true);
          setTimeout(() => {
            onClose();
            setFile(null);
            setIsUploading(false);
            setUploadProgress(0);
            setUploadSuccess(false);
          }, 1500);
        } else {
          console.error(
            "Upload failed:",
            message || "Unknown error from server"
          );
          clearInterval(timer);
          setUploadProgress(0);
          setIsUploading(false);
        }
      } catch (error: any) {
        console.error("Network error:", error.message || error);
        clearInterval(timer);
        setUploadProgress(0);
        setIsUploading(false);
      }
    }
  };

  const handleClose = () => {
    onClose();
    setFile(null);
    setIsUploading(false);
    setUploadProgress(0);
    setUploadSuccess(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="import-bulk-modal-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "90%" : isTabletScreen ? "450px" : "490px",
          height: isSmallScreen ? "353px" : isTabletScreen ? "420px" : "440px",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: isSmallScreen ? 2 : 3,
        }}
      >
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              id="import-bulk-modal-title"
              variant="h6"
              sx={{ fontWeight: 600, color: "#4A4A4A", fontFamily: "Lexend" }}
            >
              {TEXT_MESSAGES.IMPORT_BULK_PRODUCT}
            </Typography>
            <IconButton
              onClick={handleClose}
              size="small"
              sx={{ p: 0.5 }}
              aria-label="close"
            >
              <CloseIcon
                fontSize="small"
                style={{ width: "24px", height: "24px", color: "#4A4A4A" }}
              />
            </IconButton>
          </Box>

          <Paper
            elevation={0}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              width: isSmallScreen ? "auto" : "440px",
              marginTop: isSmallScreen ? 5 : 10,
              height: isSmallScreen ? "146px" : "143px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px",
              borderStyle: "dashed",
              borderColor: isDragging ? "primary.main" : "#000000",
              borderRadius: 4,
              bgcolor: isDragging ? "primary.50" : "background.paper",
              transition: "all 0.2s ease",
              textAlign: "center",
              p: isSmallScreen ? 2 : 3,
              position: "relative",
            }}
          >
            {uploadSuccess ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: isSmallScreen ? "auto" : "440px",
                  py: 3,
                  fontFamily: "Lexend",
                }}
              >
                <CheckCircleIcon
                  sx={{
                    fontSize: 48,
                    color: "success.main",
                    mb: 2,
                    border: "2px solid",
                    borderRadius: "50%",
                    borderColor: "white",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    textAlign: "center",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 600,
                    fontSize: 18,
                    color: "#4A4A4A",
                  }}
                >
                  {TEXT_MESSAGES.FILE_UPLOAD_SUCCESSFULLY}
                </Typography>
              </Box>
            ) : isUploading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                  width: "100%",
                  backgroundColor: "#F0F0FA",
                  height: "52px",
                }}
              >
                <Box sx={{ width: "80%", mb: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      marginTop: "20px",
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#1a73e8",
                        borderRadius: 4,
                      },
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={(e) => {
                  const target = e.target as HTMLElement;
                  if (!target.closest(".file-input-wrapper")) {
                  }
                }}
              >
                <div
                  className="file-input-wrapper"
                  style={{ position: "relative" }}
                >
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    style={{
                      position: "absolute",
                      inset: 0,
                      width: "100%",
                      height: "100%",
                      opacity: 0,
                      cursor: "pointer",
                    }}
                  />
                  <FileUploadOutlinedIcon
                    sx={{
                      fontSize: 40,
                      color: "#474747",
                      mb: 2,
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </div>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 1,
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000000",
                  }}
                >
                  {file ? file.name : "Choose file to upload"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#4A4A4A",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 400,
                    fontSize: 12,
                  }}
                >
                  {TEXT_MESSAGES.SUPPORT}
                </Typography>
                {error && (
                  <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
                    {error}
                  </Typography>
                )}
              </div>
            )}
          </Paper>

          {!uploadSuccess && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontFamily: "Poppins, sans-serif",
                alignItems: "center",
                flexDirection: "row",
                marginTop: isSmallScreen ? 7 : 13,
                gap: isSmallScreen ? 2 : 0,
              }}
            >
              <Button
                startIcon={<FileDownloadOutlinedIcon sx={{ color: "#4A4A4A" }} />}
                onClick={handleDownloadSample}
                disabled={isUploading}
                sx={{
                  backgroundColor: "#F0F0FA",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#000000",
                  textDecoration: "underline",
                  transform: "none",
                  textTransform: "none",
                  width: "auto",
                  height: "26px",
                  whiteSpace: "nowrap",
                }}
              >
                {TEXT_MESSAGES.SAMPLE_FILE}
              </Button>

              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexDirection: "row",
                }}
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    width: isSmallScreen ? "100%" : "97px",
                    height: "40px",
                    color: "#4A4A4A",
                    textDecoration: "underline",
                    fontWeight: 400,
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                    transform: "none",
                    textTransform: "none",
                  }}
                  disabled={isUploading}
                >
                  {TEXT_MESSAGES.CANCEL}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{
                    width: isSmallScreen ? "100%" : "auto",
                    px: 4,
                    backgroundColor: "#000000",
                    "&:hover": {
                      bgcolor: "#000000",
                    },
                  }}
                >
                  {isUploading ? "Uploading..." : "Save"}
                </Button>
              </Box>
            </Box>
          )}

          {uploadSuccess && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                fontFamily: "Poppins, sans-serif",
                alignItems: "center",
                flexDirection: "row",
                marginTop: isSmallScreen ? 7 : 13,
                gap: isSmallScreen ? 2 : 0,
              }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  width: isSmallScreen ? "100%" : "84px",
                  height:"auto",
                  borderRadius:"4px",
                  px: 4,
                  position:"absolute",
                  right:"20px",
                  backgroundColor: "#000000",
                  "&:hover": {
                    bgcolor: "#000000",
                  },
                }}
              >
                Close
              </Button>
            </Box>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ImportBulkFile;