import { useState } from "react";
import 
{ Dialog, 
  DialogContent, 
  DialogTitle, 
  DialogActions, 
  Button, 
  Checkbox,Box,Typography,IconButton } from "@mui/material";
import { Label } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { TEXT_MESSAGES } from "../const";
interface PheonixAccessProps {
    open: boolean
    onOpenChange: (open: boolean) => void
    selectedModule: string
    onSave: (accessLevels: string[]) => void
  }
  
  const accessLevels = ["Add", "View", "Edit", "Delete"]
  
  export function PheonixAccess({ open, onOpenChange, selectedModule, onSave }: PheonixAccessProps) {
    const [selectedLevels, setSelectedLevels] = useState<string[]>([])
  
    const handleCheckboxChange = (level: string) => {
      setSelectedLevels((prev) => (prev.includes(level) ? prev.filter((l) => l !== level) : [...prev, level]))
    }
  
    const handleSave = () => {
      onSave(selectedLevels)
      onOpenChange(false)
    }
    const handleSelectAll = () => {
        if (selectedLevels.length === accessLevels.length) {
          setSelectedLevels([]); 
        } else {
          setSelectedLevels([...accessLevels]); 
        }
      };
    
  
    return (
      <Dialog open={open} onClose={onOpenChange} 
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#fff",
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow:"0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
          position: "relative",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          height: "292px",
          width: "490px",
         
        },
      }}
      >
        <DialogContent>
        <DialogTitle
            sx={{fontFamily:"Lexend",
                 fontWeight:500,
                 color:"#4A4A4A",
                 fontSize:"18px",
                 display:"flex",
                 justifyContent:"space-between",
                 padding:"3px"}}>
            
            {TEXT_MESSAGES.SELECT} {selectedModule} {TEXT_MESSAGES.ACCESS}
            <IconButton
                onClick={() => onOpenChange(false)}
                size="small"
                sx={{ p: 0.5 }}
                aria-label="close"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            
            </DialogTitle>
         
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "240px",
              height: "24px",
              marginTop:"8px",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 400, fontFamily: "Lexend"}}>
            {TEXT_MESSAGES.ALL}
            </Typography>
            <Checkbox
              checked={selectedLevels.length === accessLevels.length}
              onChange={handleSelectAll}
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "black", 
                },
              }}
            />
          </Box>

          {accessLevels.map((level) => (
            <Box 
              key={level} 
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "240px", 
                height: "24px",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 400,fontFamily: "Lexend" }}>
                {`${level} ${selectedModule}`}
              </Typography>
              <Checkbox
                id={level.toLowerCase()}
                checked={selectedLevels.includes(level)}
                onChange={() => handleCheckboxChange(level)}
                sx={{
                    color: "black", 
                    "&.Mui-checked": {
                      color: "black", 
                    },
                  }}
              />
            </Box>
          ))}
        </Box>
          <DialogActions>
            <Button 
            sx={{
                color: "black",
                textDecoration: "underline",
              }}
            onClick={() => onOpenChange(false)}>Cancel</Button>
            <Button 
            variant="contained"
            sx={{
              px: 4,
              bgcolor: "black",
              "&:hover": {
                bgcolor: "black",
                opacity: 0.9,
              },
            }}
            onClick={handleSave}>{TEXT_MESSAGES.SAVE}</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }
