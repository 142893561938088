import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import ModalFormData from "../interfaces/ModalFormDataProps";
import { TEXT_MESSAGES } from "../const";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixButton from "../Components/PheonixButton";
import SupplierInfo from "./Supplierinfo";
import Creditterms from './Creditterms';

interface SupplierViewProps {
  suppliers: ModalFormData;
  onClose: () => void;
  onSave: (updatedSupplier: ModalFormData) => void;
}
const SupplierView: React.FC<SupplierViewProps> = ({
  onClose,
  onSave,
  suppliers
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [editedSupplier, setEditedSupplier] = useState<ModalFormData>(suppliers);

  const handleSave = () => {
    onSave(editedSupplier);
  };

  return (
    <Box
      sx={{
        maxWidth: isSmallScreen ? "100%" : isTabletScreen ? "90%" : "1200px",
        height: isSmallScreen ? "auto" : "auto",
        margin: "auto",
        padding: isSmallScreen ? "12px" : isTabletScreen ? "20px" : "24px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: isSmallScreen ? 16 : 20,
          fontFamily: "Lexend",
        }}
      >
        {TEXT_MESSAGES.VIEW_SUPPLIERS}
      </Typography>

      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Typography
          sx={{
            width: "90%",
            height: "32px",
            paddingY: "4px",
            paddingLeft: "16px",
            backgroundColor: "#00000026",
            fontFamily: "Lexend",
          }}
        >
          {TEXT_MESSAGES.SUPPLIER_INFO}
        </Typography>
        <SupplierInfo
          data={suppliers}
          readOnly={true}
          onSave={(updatedData) => {
            setEditedSupplier((prev) => ({
              ...prev,
              ...updatedData,
            }));
          }}
          
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors} />
      </Box>

      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Typography
          sx={{
            width: "90%",
            height: "32px",
            paddingY: "4px",
            paddingLeft: "16px",
            backgroundColor: "#00000026",
            fontFamily: "Lexend",
          }}
        >
          {TEXT_MESSAGES.CREDITTERMS}
        </Typography>
        <Creditterms
          data={suppliers}
          readOnly={true}
          onSave={(updatedData) => {
            setEditedSupplier((prev) => ({
              ...prev,
              ...updatedData,
            }));
          }}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
        />
      </Box>



      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          gap: "12px",
          flexWrap: "wrap",
        }}
      >
        <PheonixButton
          label={TEXT_MESSAGES.BACK_BUTTON}
          width={isSmallScreen ? "79px" : "90px"}
          backgroundColor={"white"}
          borderColor={"black"}
          border="1px solid"
          color={"secondary"}
          variant="outlined"

          sx={{
            color: "black",
            minWidth: "79px",
            minHeight: "33px",
            transform: "none",
            textTransform: "none",
            "&:hover": {
              borderColor: "black",
              backgroundColor: "white",
            },
          }}
          onClick={onClose}
        />
      </Box>
    </Box>
  );
};

export default SupplierView;
