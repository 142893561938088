import React, { useState, useEffect } from "react";
import { Box, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixButton from "../Components/PheonixButton";
import ProductDetails from "./Productdetails";
import StockDetails from "./Stockdetails";
import PriceDetails from "./Pricedetails";
import { TEXT_MESSAGES } from "../const";
import baseUrl from "../api";
import axios from "axios";
import { ADD_PRODUCT_MUTATION } from "../graphql/query";
import { print } from "graphql";
import { useNavigate, useLocation } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface AddProductProps {
  onClose: () => void;
  onSave: (data: any) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

interface ProductData {
  productDetails: Record<string, any>;
  stockDetails: Record<string, any>;
  priceDetails: Record<string, any>;
}

const AddProduct: React.FC<AddProductProps> = ({
  onClose,
  onSave,
  setActiveStep,
  activeStep,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [error, setError] = useState<string | null>(null);
  const [productData, setProductData] = useState<ProductData>({
    productDetails: {},
    stockDetails: {},
    priceDetails: {},
  });
  const [isFormDirty, setIsFormDirty] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});

  const steps = ["Product Details", "Stock Details", "Price Details"];

  const validateProductDetails = (data: Record<string, any>) => {
    const requiredFields = [
      "product_name",
      "product_description",
      "hsn_code",
      "quantity",
      "unit",
      "entrydate",
      "expirydate",
    ];
    return requiredFields.reduce((errors, field) => {
      if (!data[field])
        errors[field] = `${field.replace(/_/g, " ")} is required`;
      return errors;
    }, {} as Record<string, string>);
  };

  const validatePriceDetails = (data: Record<string, any>) => {
    const requiredFields = ["purchase_price", "sales_price"];
    if (data.isGST) requiredFields.push("gst_percentage", "sales_price_gst");
    return requiredFields.reduce((errors, field) => {
      if (!data[field])
        errors[field] = `${field.replace(/_/g, " ")} is required`;
      return errors;
    }, {} as Record<string, string>);
  };

  const handleNext = async () => {
    let currentErrors: Record<string, string> = {};
    if (activeStep === 0)
      currentErrors = validateProductDetails(productData.productDetails);
    else if (activeStep === 2)
      currentErrors = validatePriceDetails(productData.priceDetails);

    if (Object.keys(currentErrors).length > 0) {
      setFieldErrors(currentErrors);
      setError(Object.values(currentErrors).join(", "));
      return;
    }
    setError(null);
    setFieldErrors({});

    if (activeStep === steps.length - 1) {
      try {
        const variables = {
          ...productData.productDetails,
          ...productData.stockDetails,
          ...productData.priceDetails,
          stock_reference:
            productData.stockDetails.stock_reference?.length > 0
              ? productData.stockDetails.stock_reference.map(() => null)
              : null,
        };

        if (productData.stockDetails.stock_reference?.length > 0) {
          const formData = new FormData();

          formData.append(
            "operations",
            JSON.stringify({
              query: ADD_PRODUCT_MUTATION.loc?.source.body,
              variables,
            })
          );
          const map: Record<string, string[]> = {};
          productData.stockDetails.stock_reference.forEach(
            (_: File, index: number) => {
              map[`${index}`] = [`variables.stock_reference.${index}`];
            }
          );
          formData.append("map", JSON.stringify(map));
          productData.stockDetails.stock_reference.forEach(
            (file: File, index: number) => {
              formData.append(`${index}`, file);
            }
          );

          const response = await axios.post(baseUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          const { addProduct } = response.data.data;
          if (addProduct?.statusCode === 201) {
            onSave(productData);
            setIsFormDirty(false);
            onClose();
          } else {
            setError(addProduct?.message || "Unknown error");
          }
        } else {
          const response = await axios.post(baseUrl, {
            query: ADD_PRODUCT_MUTATION.loc?.source.body,
            variables,
          });

          const { addProduct } = response.data.data;
          if (addProduct?.statusCode === 201) {
            onSave(productData);
            setIsFormDirty(false);
            onClose();
          } else {
            setError(addProduct?.message || "Unknown error");
          }
        }
      } catch (err: any) {
        console.error("Network error:", err.message || err);
        setError("Failed to save product. Please try again.");
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
 

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleDataChange = (key: keyof ProductData, data: any) => {
    setProductData((prev) => ({
      ...prev,
      [key]: data,
    }));
    setIsFormDirty(true);
  };

  useEffect(() => {
    const handleNavigation = (event: PopStateEvent) => {
      if (
        isFormDirty &&
        !window.confirm(
          "You have unsaved changes. Do you really want to leave?"
        )
      ) {
        event.preventDefault();
        navigate(location.pathname);
      }
    };
    window.addEventListener("popstate", handleNavigation);
    return () => window.removeEventListener("popstate", handleNavigation);
  }, [isFormDirty, navigate, location.pathname]);

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        paddingX: "16px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        border: "1px solid rgba(224,224,224,1)",
        height: isSmallScreen ? "90vh" : isMediumScreen ? "80vh" : "70vh",
        overflowY: "auto",
        boxSizing: "border-box",
      }}
    >
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ fontFamily: "Lexend", padding: "10px" }}
      >
        {TEXT_MESSAGES.PDT_BREAD}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Stepper
            activeStep={activeStep}
            sx={{
              "& .MuiStepConnector-root": {
                "& .MuiStepConnector-line": {
                  width: "100px",
                  borderWidth: "2px",
                },
              },
            }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    sx: {
                      color: "#8C8C8C",
                      "&.Mui-active": { color: "black" },
                      "&.Mui-completed": { color: "black" },
                    },

                    icon: index < activeStep ? <CheckCircleIcon /> : index + 1,
                  }}
                  sx={{
                    "& .MuiStepLabel-label": {
                      fontFamily: "Lexend",
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {activeStep === 0 && (
            <ProductDetails
              data={productData.productDetails || {}}
              onSave={(data) => handleDataChange("productDetails", data)}
              error={error}
              readOnly={false}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
          )}
          {activeStep === 1 && (
            <StockDetails
              data={productData.stockDetails || {}}
              onSave={(data) => handleDataChange("stockDetails", data)}
              error={error}
              readOnly={false}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
          )}
          {activeStep === 2 && (
            <PriceDetails
              data={productData.priceDetails || {}}
              onSave={(data) => handleDataChange("priceDetails", data)}
              error={error}
              readOnly={false}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
          )}
        </Box>
        <Box
          sx={{
            paddingX: "16px",
            paddingY: "10px",
            width: isSmallScreen ? "85%" : isMediumScreen ? "80%" : "90%",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {activeStep > 0 && (
              <PheonixButton
                label={TEXT_MESSAGES.BACK_BUTTON}
                width={isSmallScreen ? "79px" : "90px"}
                backgroundColor={"white"}
                borderColor={"black"}
                border="1px solid"
                color={"secondary"}
                variant="outlined"
                onClick={handleBack}
                sx={{
                  color: "black",
                  minWidth: "79px",
                  minHeight: "33px",
                  transform: "none",
                  textTransform: "none",
                  "&:hover": { borderColor: "black", backgroundColor: "white" },
                }}
              />
            )}
            <PheonixButton
              label={
                activeStep === 2
                  ? TEXT_MESSAGES.SAVE_BUTTON
                  : TEXT_MESSAGES.NEXT_BUTTON
              }
              variant="outlined"
              width={isSmallScreen ? "79px" : "90px"}
              onClick={handleNext}
              sx={{
                backgroundColor: "black",
                minWidth: "79px",
                minHeight: "33px",
                color: "white",
                transform: "none",
                textTransform: "none",
                "&:hover": { backgroundColor: "black" },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddProduct;
