import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ModalFormData from "../interfaces/ModalFormDataProps";
import PheonixButton from "../Components/PheonixButton";
import { TEXT_MESSAGES } from "../const";

interface ViewQuotationProps {
  onClose: () => void;
  quotation: ModalFormData;
  onSave?: (data: any) => void;
}
const currencySymbols: { [key: string]: string } = {
  USD: "$",
  INR: "₹",
  EURO: "€",
};
const ViewQuotation: React.FC<ViewQuotationProps> = ({
  quotation,
  onClose,
  onSave,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [currencySymbol, setCurrencySymbol] = useState<string>("");

  useEffect(() => {
    if (quotation.currency && currencySymbols[quotation.currency]) {
      setCurrencySymbol(currencySymbols[quotation.currency]);
    } else {
      setCurrencySymbol("");
    }
  }, [quotation.currency]);

  const cgst = (quotation.cgst / 100) * quotation.totalamountwithouttax;
  const sgst = (quotation.sgst / 100) * quotation.totalamountwithouttax;
  const taxAmount = Math.round(cgst + sgst);

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        padding: { xs: "10px", sm: "20px" },
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        border: "1px solid rgba(224,224,224,1)",
        minHeight: "70vh",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          padding: "16px",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isSmallScreen ? "flex-start" : "center",
          gap: 2,
        }}
      >
        <Box sx={{ fontFamily: "Lexend" }}>
          <Typography sx={{ fontFamily: "Lexend" }}>
            <b>{TEXT_MESSAGES.QUOTATION_NUMBER}</b>{" "}
            {quotation.quotation_code || "N/A"}
          </Typography>
          <Typography sx={{ fontFamily: "Lexend" }}>
            <b>{TEXT_MESSAGES.CUSTOMER_NAME}</b>{" "}
            {quotation.customer_name || "N/A"}
          </Typography>
          <Typography sx={{ fontFamily: "Lexend" }}>
            <b>{TEXT_MESSAGES.QUOTATION_STATUS}</b> {quotation.status || "N/A"}
          </Typography>
        </Box>
        <Box sx={{ fontFamily: "Lexend" }}>
          <Typography sx={{ fontFamily: "Lexend" }}>
            <b>{TEXT_MESSAGES.QUOTATION_DATE}</b>{" "}
            {quotation.date
              ? new Date(quotation.date).toLocaleDateString("en-GB")
              : "N/A"}
          </Typography>
          <Typography sx={{ fontFamily: "Lexend" }}>
            <b>{TEXT_MESSAGES.EXPIRY}</b>{" "}
            {quotation.expiry_date
              ? new Date(quotation.expiry_date).toLocaleDateString("en-GB")
              : "N/A"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <Table
          sx={{
            minWidth: 600,
            borderCollapse: "collapse",
            fontFamily: "Lexend",
          }}
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              {[
                "#",
                "Product Name",
                "Description",
                "HSN Code",
                "Quantity",
                "Unit",
                "Rate Per Item",
                "Taxes",
                "Amount",
              ].map((heading) => (
                <TableCell
                  key={heading}
                  sx={{
                    textAlign: "center",
                    padding: "8px",
                    border: "1px solid #ddd",
                    fontFamily: "Lexend",
                  }}
                >
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quotation.productdata.map((product, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: "8px",
                    border: "1px solid #ddd",
                    fontFamily: "Lexend",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                  {product.product_name}
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                  {product.product_description}
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                  {product.hsn_code}
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                  {product.quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                  {product.unit}
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                  {product.sales_rate % 1 !== 0
                    ? product.sales_rate.toFixed(2)
                    : String(product.sales_rate)}
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                  {product.sales_price_gst}%
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                  {currencySymbol}
                  {product.sales_price % 1 !== 0
                    ? product.sales_price.toFixed(2)
                    : product.sales_price}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "flex-end",
          padding: "16px",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography sx={{ color: "#00000061", fontFamily: "Lexend" }}>
            {TEXT_MESSAGES.CUSTOMER_NOTESLABEL}
          </Typography>
          <textarea
            value={quotation.customernotes}
            style={{
              border: "0.5px solid",
              borderRadius: "10px",
              width: isSmallScreen ? "90%" : "464px",
              height: "68px",
              fontFamily: "Lexend",
              fontSize: "16px",
            }}
          />
          <Typography sx={{ color: "#00000061", fontFamily: "Lexend" }}>
            {TEXT_MESSAGES.TERMS_AND_CONDITION}
          </Typography>
          <textarea
            value={quotation.termsandconditions}
            style={{
              border: "0.5px solid",
              borderRadius: "10px",
              width: isSmallScreen ? "90%" : "464px",
              height: "68px",
              fontFamily: "Lexend",
              fontSize: "16px",
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          {quotation.currency === "INR" && (
            <Table
              sx={{
                borderCollapse: "collapse",
                marginLeft: isSmallScreen ? "0" : "auto",
                width: isSmallScreen ? "100%" : "50%",
                fontFamily: "Lexend",
              }}
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                  {["CGST in %", "SGST in %", "Amount"].map((heading) => (
                    <TableCell
                      key={heading}
                      sx={{
                        textAlign: "center",
                        padding: isSmallScreen ? "4px" : "8px",
                        fontFamily: "Lexend",
                      }}
                    >
                      {heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                    {quotation.cgst}%
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                    {quotation.sgst}%
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontFamily: "Lexend" }}>
                    {taxAmount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          <Table
            sx={{
              width: "100%",
              borderCollapse: "collapse",
              mt: 2,
              "& td": {
                border: "none",
                fontFamily: "Lexend",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "right",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  {TEXT_MESSAGES.TOTAL_AMOUNT}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", padding: "8px" }}
                  colSpan={2}
                >
                  {currencySymbol} 
                  {Number(quotation.totalamountwithouttax) % 1 !== 0
  ? Number(quotation.totalamountwithouttax).toFixed(2)
  : Number(quotation.totalamountwithouttax)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "right",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  {TEXT_MESSAGES.ROUND_OFF}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", padding: "8px" }}
                  colSpan={2}
                >
                  {quotation.roundoff}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "right",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  {TEXT_MESSAGES.TOTAL}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", padding: "8px" }}
                  colSpan={2}
                >
                  {currencySymbol} {quotation.grandtotal}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "16px",
          marginTop: "50px",
        }}
      >
        <PheonixButton
          label={TEXT_MESSAGES.BACK_BUTTON}
          width={isSmallScreen ? "79px" : "90px"}
          backgroundColor={"white"}
          borderColor={"black"}
          border="1px solid"
          color={"secondary"}
          variant="outlined"
          sx={{
            color: "black",
            minWidth: "79px",
            minHeight: "33px",
            transform: "none",
            textTransform: "none",
            "&:hover": {
              borderColor: "black",
              backgroundColor: "white",
            },
          }}
          onClick={onClose}
        />
      </Box>
    </Box>
  );
};

export default ViewQuotation;


