import React, { useState } from "react";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import PheonixButton from "../Components/PheonixButton";
import { useTheme } from "@mui/material/styles";
import ProductDetails from "./Productdetails";
import StockDetails from "./Stockdetails";
import PriceDetails from "./Pricedetails";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import ModalFormData from "../interfaces/ModalFormDataProps";
import { UPDATE_PRODUCT_MUTATION } from "../graphql/query";
import axios from "axios";
import baseUrl from "../api";
import { print } from "graphql";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface EditProductProps {
  product: ModalFormData;
  onSave: (updatedProduct: ModalFormData) => void;
  onCancel: () => void;
  error?: string | null;
  onFileChange: (files: File[]) => void;
}

const steps = ["Product Details", "Stock Details", "Price Details"];

const ProductEdit: React.FC<EditProductProps> = ({
  product,
  onSave,
  onCancel,
  onFileChange,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [editedProduct, setEditedProduct] = useState<ModalFormData>(product);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [files, setFiles] = useState<File[]>([]);

  const validateProductDetails = (
    data: Record<string, any>,
    checkAll = false
  ) => {
    const requiredFields = [
      "product_name",
      "product_description",
      "quantity",
      "unit",
      "hsn_code",
      "entrydate",
      "expirydate",
    ];
    let errors: Record<string, string> = {};
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field.replace(/_/g, " ")} is required`;
      }
    });
    return errors;
  };
  const validatePriceDetails = (data: Record<string, any>) => {
    const requiredFields = [
      "purchase_price",
      "sales_price",
    ];
    if (data.isGST) requiredFields.push("gst_percentage", "sales_price_gst");
    return requiredFields.reduce((errors, field) => {
      if (!data[field])
        errors[field] = `${field.replace(/_/g, " ")} is required`;
      return errors;
    }, {} as Record<string, string>);
  };

  const handleFileChange = (newFiles: File[]) => {
    const validFiles = newFiles.filter((file) => {
      if (file.type !== "application/pdf") {
        setError(`Invalid file: ${file.name}. Only PDF files are allowed.`);
        return false;
      }
      return true;
    });
    if (validFiles.length === 0) return;
    setFiles((prev) => {
      const updatedFiles = [...prev, ...validFiles];
      onFileChange(updatedFiles);
      return updatedFiles;
    });
  };

  const handleNext = async () => {
    let currentErrors: Record<string, string> = {};
    if (activeStep === 0) {
      currentErrors = validateProductDetails(editedProduct);
    } else if (activeStep === 2) {
      currentErrors = validatePriceDetails(editedProduct);
    }

    if (Object.keys(currentErrors).length > 0) {
      setFieldErrors(currentErrors);
      setError(Object.values(currentErrors).join(", "));
      return;
    }
    setError(null);
    setFieldErrors({});

    if (activeStep === steps.length - 1) {
      try {
        const { id, ...editedData } = editedProduct;

        const variables = {
          id,
          ...editedData,
          stock_reference:
            files.length > 0
              ? files.map(() => null)
              : editedProduct.stock_reference || null,
        };

        if (files.length > 0) {
          const formData = new FormData();

          formData.append(
            "operations",
            JSON.stringify({
              query: print(UPDATE_PRODUCT_MUTATION),
              variables,
            })
          );

          const map: Record<string, string[]> = {};
          files.forEach((_, index) => {
            map[`${index}`] = [`variables.stock_reference.${index}`];
          });
          formData.append("map", JSON.stringify(map));

          files.forEach((file, index) => {
            formData.append(`${index}`, file);
          });

          const response = await axios.post(baseUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          const { editProduct } = response.data.data;
          if (editProduct?.statusCode === 200) {
            onSave({
              ...editedProduct,
              stock_reference:
                files.length > 0
                  ? files.map((file) => file.name)
                  : editedProduct.stock_reference,
            });
            onCancel();
          } else {
            setError(editProduct?.message || ERROR_MESSAGES.FAILED_UPDATE);
          }
        } else {
          const response = await axios.post(baseUrl, {
            query: print(UPDATE_PRODUCT_MUTATION),
            variables,
          });

          const { editProduct } = response.data.data;
          if (editProduct?.statusCode === 200) {
            onSave({
              ...editedProduct,
              stock_reference: editedProduct.stock_reference,
            });
            onCancel();
          } else {
            setError(editProduct?.message || ERROR_MESSAGES.FAILED_UPDATE);
          }
        }
      } catch (err: any) {
        console.error(ERROR_MESSAGES.NETWORK_ERROR, err.message || err);
        setError(ERROR_MESSAGES.FAILED_UPDATE);
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        paddingX: "16px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        border: "1px solid",
        borderColor: "rgba(224,224,224,1)",
        height: isSmallScreen ? "90vh" : isMediumScreen ? "80vh" : "70vh",
        overflowY: "auto",
        boxSizing: "border-box",
      }}
    >
      <Box>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginBottom: "16px", fontFamily: "Lexend", padding: "10px" }}
        >
          {TEXT_MESSAGES.EDIT}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Stepper
            activeStep={activeStep}
            sx={{
              "& .MuiStepConnector-root": {
                "& .MuiStepConnector-line": {
                  width: "100px",
                  borderWidth: "2px",
                },
              },
            }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    sx: {
                      color: "#8C8C8C",
                      "&.Mui-active": { color: "black" },
                      "&.Mui-completed": { color: "black" },
                    },

                    icon: index < activeStep ? <CheckCircleIcon /> : index + 1,
                  }}
                  sx={{
                    "& .MuiStepLabel-label": {
                      fontFamily: "Lexend",
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          {activeStep === 0 && (
            <ProductDetails
              data={{
                product_name: editedProduct.product_name,
                entrydate: editedProduct.entrydate,
                expirydate: editedProduct.expirydate,
                hsn_code: editedProduct.hsn_code,
                product_description: editedProduct.product_description,
                quantity: editedProduct.quantity,
                unit: editedProduct.unit,
              }}
              onSave={(updatedData) =>
                setEditedProduct((prev) => ({ ...prev, ...updatedData }))
              }
              error={error}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
          )}
          {activeStep === 1 && (
            <StockDetails
              data={{
                stock_alert: editedProduct.stock_alert,
                stock_reference: editedProduct.stock_reference,
                stock_notes: editedProduct.stock_notes,
                isalert:editedProduct.isalert,
              }}
              onSave={(updatedData) =>
                setEditedProduct((prev) => ({ ...prev, ...updatedData }))
              }
              onFileChange={handleFileChange}
              error={error}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
          )}
          {activeStep === 2 && (
            <PriceDetails
              data={{
                sales_price_gst: editedProduct.sales_price_gst,
                purchase_price: editedProduct.purchase_price,
                sales_price: editedProduct.sales_price,
                gst_percentage: editedProduct.gst_percentage,
                isGST: editedProduct.isGST,
              }}
              onSave={(updatedData) =>
                setEditedProduct((prev) => ({ ...prev, ...updatedData }))
              }
              error={error}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "auto",
          paddingX: "16px",
          paddingY: "10px",
          width: isSmallScreen ? "85%" : isMediumScreen ? "80%" : "90%",
          backgroundColor: "white",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {activeStep > 0 && (
            <PheonixButton
              label={TEXT_MESSAGES.BACK_BUTTON}
              width={isSmallScreen ? "79px" : "90px"}
              backgroundColor={"white"}
              borderColor={"black"}
              border="1px solid"
              color={"secondary"}
              variant="outlined"
              onClick={handleBack}
              sx={{
                color: "black",
                minWidth: "79px",
                minHeight: "33px",
                transform: "none",
                textTransform: "none",
                "&:hover": { borderColor: "black", backgroundColor: "white" },
              }}
            />
          )}
          <PheonixButton
            label={
              activeStep === 2
                ? TEXT_MESSAGES.SAVE_BUTTON
                : TEXT_MESSAGES.NEXT_BUTTON
            }
            variant="outlined"
            width={isSmallScreen ? "79px" : "90px"}
            onClick={handleNext}
            sx={{
              backgroundColor: "black",
              minWidth: "79px",
              minHeight: "33px",
              color: "white",
              transform: "none",
              textTransform: "none",
              "&:hover": { backgroundColor: "black" },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProductEdit;
