import React from "react";
import { useState, useEffect, ReactNode } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import PheonixTextField from "../Components/PheonixTextField";
import { TEXT_MESSAGES } from "../const";
import { PheonixSnackbar } from "pheonixui";
import CloseIcon from "@mui/icons-material/Close";

interface PriceDetailsProps {
  data: Record<string, any>;
  onSave: (data: Record<string, any>) => void;
  error?: string | null;
  readOnly?: boolean;
  fieldErrors: Record<string, string>;
  setFieldErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}
const PriceDetails: React.FC<PriceDetailsProps> = ({
  data,
  onSave,
  error,
  readOnly,
  fieldErrors,
  setFieldErrors,
}) => {
  const [isGST, setIsGST] = useState(data.isGST ?? true);
  const [gst, setGST] = useState<string | null>(null);
  const [unit, setUnit] = useState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [formData, setFormData] = useState(data);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [succMessage, setSuccMessage] = useState<ReactNode>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );

  const commonStyles = {
    textField: {
      width: "100%",
      maxWidth: isTabletScreen ? "380px" : "462px",
      height: "48px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root": {
        color: "gray",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "gray",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "10px",
        fontFamily: "Lexend",
        margin: 0,
        Padding: 0,
      },
    },
    autoComplete: {
      width: "100%",
      maxWidth: isTabletScreen ? "380px" : "462px",
      "& .MuiAutocomplete-root": {
        color: "#00000061",
      },
    },
  };

  const gstOptions = [
    { value: "0%", label: "0%" },
    { value: "5%", label: "5%" },
    { value: "12%", label: "12%" },
    { value: "18%", label: "18%" },
    { value: "28%", label: "28%" },
  ];

  const unitOptions = [
    { value: "Bags", label: "Bags" },
    { value: "Kilogram", label: "Kilogram" },
    { value: "Gram", label: "Gram" },
    { value: "Centimeter", label: "Centimeter" },
  ];

  const handleSwitchChange = () => {
    const newIsGST = !isGST;
      setIsGST(newIsGST);
     handleInputChange("isGST", newIsGST);
  };

  useEffect(() => {
    setIsGST(data.isGST ?? true);
    setFormData(data);
  }, [data]);

  useEffect(() => {
    setTouched((prev) => ({
      ...prev,
      gst_percentage: formData.isGST ? formData.gst_percentage : null,
      sales_price_gst: formData.isGST ? formData.sales_price_gst : null,
    }));
  }, [formData.isGST, formData.gst_percentage, formData.sales_price_gst]);

  const handleInputChange = (field: string, value: any) => {
    let parsedValue = value;
    const floatFields = ["purchase_price", "sales_price"];

    if (floatFields.includes(field)) {
      parsedValue = value === "" ? null : parseFloat(value) || 0;
    }

    if (
      field === "gst_percentage" &&
      typeof value === "string" &&
      !value.endsWith("%")
    ) {
      parsedValue = `${value}%`;
    }

    let updatedData = { ...formData, [field]: parsedValue };

    if (field === "isGST") {
      updatedData = {
        ...updatedData,
        isGST: value,
        gst_percentage: value ? updatedData.gst_percentage ?? 0 : null,
        sales_price_gst: value ? updatedData.sales_price ?? 0 : null, 
      };
    }
    if (!updatedData.isGST) {
      updatedData.sales_price_gst = null; 
    } else if (field === "sales_price" || field === "gst_percentage") {
      const salesPrice = parseFloat(updatedData.sales_price) || 0;
      const gstPercentage = parseFloat(updatedData.gst_percentage) || 0;
      updatedData.sales_price_gst = isNaN(gstPercentage)
        ? salesPrice
        : salesPrice * (1 + gstPercentage / 100);
    }

    setFormData(updatedData);
    onSave(updatedData);
  };

  const validateField = (
    field: string,
    value: any,
    formData: Record<string, any>
  ) => {
    let error = "";

    if (["purchase_price", "sales_price"].includes(field) && !value) {
      error = `${field.replace("_", " ")} is required`;
    }

    if (formData.isGST) {
      if (
        ["gst_percentage", "sales_price_gst"].includes(field) &&
        (value === null || value === "")
      ) {
        error = `${field.replace("_", " ")} is required`;
      }
    } else {
      if (["gst_percentage", "sales_price_gst"].includes(field)) {
        error = "";
      }
    }

    return error;
  };
  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const error = validateField(field, formData[field], formData);
    setFieldErrors((prev) => ({ ...prev, [field]: error }));
    if (field === "sales_price") {
      const purchasePrice = parseFloat(formData.purchase_price) || 0;
      const salesPrice = parseFloat(formData.sales_price) || 0;
      setTimeout(() => {
        if (salesPrice < purchasePrice) {
          setSuccMessage(
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: "#663C00",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {TEXT_MESSAGES.ALERT}
            </span>
          );
          setSnackbarSeverity("error");
          setModalOpen(true);
          setTimeout(() => {
            setModalOpen(false);
          }, 3000);
        }
      }, 500);
    }
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen
          ? "column"
          : isTabletScreen
          ? "column"
          : "row",
        gap: isSmallScreen ? "20px" : isTabletScreen ? "20px" : "24px",
        padding: isSmallScreen ? "16px" : isTabletScreen ? "20px" : "24px",
        height: "auto",
        overflow: "auto",
      }}
    >
      <Box
        sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <PheonixTextField
          type="text"
          id="outlined-error"
          label="Purchase Price"
          variant="standard"
          disabled={readOnly}
          value={formData.purchase_price}
          error={!!fieldErrors.purchase_price}
          helperText={fieldErrors.purchase_price || ""}
          onChange={(e) => handleInputChange("purchase_price", e.target.value)}
          onBlur={() => handleBlur("purchase_price")}
          required
          sx={commonStyles.textField}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            width: isSmallScreen ? "100%" : "348px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                fontSize: "14px",
                whiteSpace: "nowrap",
                fontFamily: "Lexend",
              }}
            >
              {TEXT_MESSAGES.GST}
            </Typography>
            <Switch
              checked={formData.isGST}
              disabled={readOnly}
              onChange={handleSwitchChange}
              size="small"
              sx={{
                width: "40px",
                padding: "0",
                "& .MuiSwitch-thumb": {
                  width: "16px",
                  height: "16px",
                  backgroundColor: "black",
                  boxShadow: "none",
                },
                "& .MuiSwitch-switchBase": {
                  padding: "2px",
                  "&.Mui-checked": {
                    transform: "translateX(20px)",
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "black",
                    },
                  },
                },
                "& .MuiSwitch-track": {
                  borderRadius: "10px",
                  backgroundColor: "gray",
                  width: "26px",
                  height: "10px",
                  margin: "5px",
                  transition: "background-color 0.2s",
                  "&.Mui-checked": {
                    backgroundColor: "black",
                  },
                },
              }}
            />
          </Box>
          {formData.isGST && (
            <Autocomplete
              options={gstOptions}
              disabled={readOnly}
              getOptionLabel={(option) => option.label}
              value={
                gstOptions.find(
                  (option) => option.value === formData.gst_percentage
                ) || null
              }
              onChange={(_, newValue) =>
                handleInputChange("gst_percentage", newValue?.value || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={readOnly}
                  required={formData.isGST}
                  error={!!fieldErrors.gst_percentage}
                  helperText={fieldErrors.gst_percentage || ""}
                  onBlur={() => handleBlur("gst_percentage")}
                  label="GST Percentage"
                  type="text"
                  variant="standard"
                  sx={{
                    ...commonStyles.textField,
                    width: "100%",
                    maxWidth: isTabletScreen ? "380px" : "462px",
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} style={{ fontFamily: "Lexend" }}>
                  {option.label}
                </li>
              )}
              sx={{
                ...commonStyles.autoComplete,
                width: isSmallScreen
                  ? "100%"
                  : isMediumScreen
                  ? "380px"
                  : "462px",
              }}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "43px" }}
      >
        <PheonixTextField
          type="text"
          id="outlined-error"
          label="Sales Price(Total Amount without GST)"
          variant="standard"
          required
          disabled={readOnly}
          value={formData.sales_price}
          error={!!fieldErrors.sales_price}
          helperText={fieldErrors.sales_price || ""}
          onBlur={() => handleBlur("sales_price")}
          onChange={(e) => handleInputChange("sales_price", e.target.value)}
          sx={commonStyles.textField}
        />
        {formData.isGST && (
          <PheonixTextField
            type="text"
            label="Sales Price (Total Amount with GST)"
            disabled={readOnly}
            variant="standard"
            value={
              formData.isGST && formData.sales_price_gst !== null
                ? formData.sales_price_gst.toFixed(2)
                : ""
            }
            error={formData.isGST && !!fieldErrors.sales_price_gst}
            helperText={formData.isGST ? fieldErrors.sales_price_gst || "" : ""}
            onChange={(e) =>
              handleInputChange("sales_price_gst", e.target.value)
            }
            onBlur={() => handleBlur("sales_price_gst")}
            sx={{
              ...commonStyles.textField,
              visibility: readOnly || formData.isGST ? "visible" : "hidden",
              width: isSmallScreen ? "100%" : "462px",
              fontFamily: "Lexend",
              fontWeight:"bold",
              
            }}
          />
        )}
      </Box>
      <PheonixSnackbar
        vertical="top"
        horizontal="right"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        message={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
              gap: isSmallScreen ? "5px" : "10px",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: "#663C00",
                fontWeight: 500,
                fontSize: isSmallScreen
                  ? "12px"
                  : isTabletScreen
                  ? "12px"
                  : "16px",
                fontFamily: "Roboto",
                flex: 1,
                lineHeight: isSmallScreen ? "1.2" : "1.5",
              }}
            >
              {TEXT_MESSAGES.ALERT}
            </span>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setModalOpen(false)}
              disableRipple
              style={{ padding: 0 }}
            >
              <CloseIcon
                fontSize="small"
                style={{ fontWeight: "bold", color: "#663C00" }}
              />
            </IconButton>
          </div>
        }
        backgroundColor={"#D32F2F29"}
        color={"#663C00"}
        sx={{
          fontFamily: "Lexend",
          top: "170px",
          fontWeight: 500,
          fontSize: "14px",
          mt: isSmallScreen ? 16 : isTabletScreen ? 6 : 10,
          paddingTop: isSmallScreen ? "4px" : "6px",
          paddingBottom: isSmallScreen ? "3px" : "6px",
          height: isSmallScreen ? "35px" : "35px",
          width: isSmallScreen ? "95%" : isTabletScreen ? "auto" : "auto",
          maxWidth: "550px",
          minWidth: "180px",
          whiteSpace: isSmallScreen ? "normal" : "nowrap",
        }}
      />
    </Box>
  );
};

export default PriceDetails;
