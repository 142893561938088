import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup as MUIRadioGroup,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import axios from "axios";
import { GET_LAST_QUOTATION } from "../graphql/query";
import baseUrl from "../api";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { PheonixButton } from "pheonixui";

interface QuotationPreferencesDialogProps {
  open: boolean;
  onClose: () => void;
  mode: "add" | "edit";
  existingQuotationNumber?: string;
  onConfirm: (finalQuotationCode: string) => void;
}

export default function QuotationModel({
  open,
  onClose,
  mode,
  existingQuotationNumber,
  onConfirm,
}: QuotationPreferencesDialogProps) {
  const [preference, setPreference] = useState<"auto" | "manual">("auto");
  const [quotationNumber, setQuotationNumber] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastAutoGeneratedCode, setLastAutoGeneratedCode] = useState("");
  const [isValidationSuccess, setIsValidationSuccess] = useState(false);
  const { data: quotation } = useQuery(GET_LAST_QUOTATION);

  useEffect(() => {
    if (open) {
      setErrorMessage("");
      setIsValid(true);
      setLoading(false);
      setPreference("auto");
      setIsValidationSuccess(false);

      if (mode === "edit" && existingQuotationNumber) {
        setQuotationNumber(existingQuotationNumber);
        setPreference("manual");
        setIsValidationSuccess(true);
      } else if (mode === "add") {
        setQuotationNumber("");
      }
    }
  }, [open, mode, existingQuotationNumber]);

  useEffect(() => {
    if (mode === "add" && quotation?.getLastQuotation?.quotation_code) {
      const lastCode = quotation.getLastQuotation.quotation_code;

      if (/^QTN-\d{6}$/.test(lastCode)) {
        setLastAutoGeneratedCode(lastCode);
      }
    }
  }, [mode, quotation]);

  const generateNextQuotationNumber = () => {
    let lastNumber = lastAutoGeneratedCode
      ? parseInt(lastAutoGeneratedCode.replace("QTN-", ""), 10)
      : parseInt(
          localStorage.getItem("lastAutoGeneratedQTN")?.replace("QTN-", "") ||
            "0",
          10
        );

    let newQuotationNumber = `QTN-${String(lastNumber + 1).padStart(6, "0")}`;
    setLastAutoGeneratedCode(newQuotationNumber);
    localStorage.setItem("lastAutoGeneratedQTN", newQuotationNumber);

    return newQuotationNumber;
  };

  const handleValidateQTN = async () => {
    const trimmedValue = quotationNumber.trim();
    setQuotationNumber(trimmedValue);

    if (mode === "edit" && trimmedValue === existingQuotationNumber) {
      setErrorMessage("");
      setIsValid(true);
      setIsValidationSuccess(true);
      return;
    }

    if (!trimmedValue) {
      setErrorMessage(ERROR_MESSAGES.QUOTATION_EMPTY);
      setIsValid(false);
      setIsValidationSuccess(false);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        baseUrl,
        {
          query: `
            query CheckQuotationExists($quotation_code: String!) {
              checkQuotationExists(quotation_code: $quotation_code)
            }
          `,
          variables: { quotation_code: trimmedValue },
        },
        { headers: { "Content-Type": "application/json" } }
      );

      const exists = response.data?.data?.checkQuotationExists;
      if (exists) {
        setErrorMessage(ERROR_MESSAGES.QUOTATION_EXIST);
        setIsValid(false);
        setIsValidationSuccess(false);
      } else {
        setErrorMessage(ERROR_MESSAGES.QUOTATION_AVAILABLE);
        setIsValid(true);
        setIsValidationSuccess(true);
      }
    } catch (error) {
      setErrorMessage(ERROR_MESSAGES.QUOTATION_VALIDATION);
      setIsValid(false);
      setIsValidationSuccess(false);
      console.error(ERROR_MESSAGES.QUOTATION_ERROR_VALIDATE, error);
    }
    setLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuotationNumber(e.target.value);
    setErrorMessage("");
    setIsValid(true);
    setIsValidationSuccess(false);
  };

  const handleConfirm = async () => {
    if (preference === "manual") {
      setLoading(true);
      await handleValidateQTN();
      setLoading(false);
      if (!isValidationSuccess) return;
    }

    let finalQuotationCode =
      mode === "edit"
        ? quotationNumber
        : preference === "auto"
        ? generateNextQuotationNumber()
        : quotationNumber;

    if (preference === "auto") {
      setLastAutoGeneratedCode(finalQuotationCode);
      localStorage.setItem("lastAutoGeneratedQTN", finalQuotationCode);
    }

    onConfirm(finalQuotationCode);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ maxWidth: "500px", p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {mode === "add"
            ? TEXT_MESSAGES.QUOTATION_PREFERENCE
            : TEXT_MESSAGES.EDIT_QUOTATION_NUMBER}
        </Typography>

        <Box sx={{ py: 2 }}>
          {mode === "add" && (
            <MUIRadioGroup
              value={preference}
              onChange={(e) =>
                setPreference(e.target.value as "auto" | "manual")
              }
            >
              <FormControlLabel
                value="auto"
                control={<Radio />}
                label="Auto-generate quotation number"
              />
              <FormControlLabel
                value="manual"
                control={<Radio />}
                label="Enter quotation number manually"
              />
            </MUIRadioGroup>
          )}
          <Typography
            sx={{ mb: 2, display: mode === "edit" ? "block" : "none" }}
          >
            {TEXT_MESSAGES.YOUR_MODEL_EDITABLE}
          </Typography>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              gap: 1,
              position: "relative",
            }}
          >
            <TextField
              value={quotationNumber}
              onChange={handleChange}
              onBlur={preference === "manual" ? handleValidateQTN : undefined}
              size="small"
              sx={{ maxWidth: "220px" }}
              error={!isValid}
              helperText={errorMessage}
              disabled={preference === "auto" && mode === "add"}
              FormHelperTextProps={{
                sx: {
                  color: !isValid ? "red" : "green",
                  position: "absolute",
                  top: "100%",
                  left: 0,
                },
              }}
            />
            {preference === "manual" &&
              (loading ? (
                <CircularProgress size={20} />
              ) : (
                <PheonixButton
                  label="Validate"
                  variant="outlined"
                  onClick={handleValidateQTN}
                  sx={{
                    backgroundColor: "#1f2937",
                    maxWidth: "80px",
                    minHeight: "33px",
                    color: "white",
                    textTransform: "none",
                    fontFamily: "Lexend",
                    position: "relative",
                  }}
                />
              ))}
          </Box>
        </Box>
        <DialogActions sx={{ justifyContent: "flex-end", gap: 1 }}>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ fontFamily: "Lexend", textTransform: "none" }}
          >
            {TEXT_MESSAGES.CANCEL}
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            disabled={preference === "manual" && !isValidationSuccess}
            sx={{
              bgcolor: "#1f2937",
              "&:hover": { bgcolor: "#111827" },
              fontFamily: "Lexend",
              textTransform: "none",
            }}
          >
            {TEXT_MESSAGES.CONFIRM}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
