import { DELETE_PRODUCT, DELETE_QUOTATION } from "./graphql/query";

export const TEXT_MESSAGES = {
    LOG_BUTTON:"Sign in to Dasboard",
    EMAIL_REG: /^[a-zA-Z0-9._%+-]+@(?!.*\.\w{0,2}$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    LOG_TXT:"Login",
    FORGOT_TXT:"Forgot Password",
    ENTER:"Enter",
    Progresstext:"In the works, coming your way!",
    PRODUCT:"Product",
    STOCKS:"Stock",
    ADD_PARTY:"+ Add Party",
    ADD_STAFF:"+ Add Staff",
    ADD_STOCK:"+ Add Stock",
    ADD_PRODUCT:"+ Add Product",
    STOCK_BREAD:"Add Stock",
    STAFF_BREAD:"Add Staff",
    PARTY_BREAD:"Add Party",
    PDT_BREAD:"Add Product",
    IMPORT_PRODUCT:"Import Bulk Products",
    VIEW_PRODUCT:"View",
    EDIT_PRODUCT:"Edit",
    NO_PRODUCT:"No products are available",
    CURRENT_PRODUCT:"Your Current Products",
    EDIT_BUTTON:"Edit",
    AVAILABLE_SERVICE:"Available Services",
    VIEW:"View",
    BACK_BUTTON:"Back",
    SAVE_BUTTON:"Save",
    NEXT_BUTTON:"Next",
    GST:"GST",
    STOCK_ALERT_COUNT:"Stock Alert Count",
    VIEW_PRODUCTS:" View Product",
    PRODUCT_DETAILS:"Product Details",
    STOCK_DETAILS:" Stock Details",
    PRICE_DETAILS:"Price Details",
    DELETE_PRODUCT:"{code} Deleted Successfully",
    ADDING_PRODUCT:"{code} Added Successfully",
    EDITED_PRODUCT:"{code} Edited Successfully",
    DELETE_INFO:"Are you sure want to Delete?",
    INFORMATION:"Information",
    NO:"No",
    YES:"YES",
    EDIT_INFO:"Are you sure want to Edit?",
    IMPORT_BULK_PRODUCT:"Import Bulk Product",
    SUPPORT:"Supports only CSV File formats",
    SAMPLE_FILE:"Sample file format",
    CANCEL:"Cancel",
    ERROR_PRODUCT:"Product name already exist",
    ENTER_UNIQUE_NAME:"Enter a unique product name",
    FILE_UPLOAD_SUCCESSFULLY:" File Imported Successfully",
    CURRENT_STOCK:"Your current stock",
    NO_STOCK:"No stock Available",
    IMPORT_BULK_STOCK:"Import Bulk Stock",
    ADD:"Add",
    NO_STAFF:"No staff Available",
    CURRENT_STAFF:"Your current staff",
    SELECT:"Select Your",
    ACCESS:"Access Level",
    ALL:"All",
    SAVE:"Save",
    STAFF:"Staff",
    ADDING_STAFF:"Staff added successfully",
    DELETE_STAFF:"Staff Deleted successfully",
    EDITED_STAFF:"Staff Edited successfully",
    ADDING_STOCK:"Stock added successfully",
    ADD_NEW_PRODUCT:"Add new product",
    SUPPLIER:"Suppliers",
    ADD_SUPPLIER:"+ Add Suppliers",
    NO_SUPPLIER:"No suppliers are available",
    CURRENT_SUPPLIER:"Your Current Suppliers",
    ADD_TITLE:"Add Suppliers",
    VIEW_SUPPLIERS:"View Suppliers",
    SUPPLIER_INFO:"Suppliers Personal Info",
    CREDITTERMS:"Suppliers Credit Terms",
    ADDSUPPLIER:"Add",
    EDITSUPPLIER:"Edit",
    VIEWSUPPLIER:"View",
    CUSTOMER:"Customer",
    ADD_CUSTOMER:"+ Add Customer",
    NO_CUSTOMER:"No customers are available",
    CURRENT_CUSTOMER:"Your Current customer",
    VIEW_CUSTOMERS:"View Customer",
    CUSTOMER_INFO:"Cutomer Personal Info",
    ADDCUSTOMER:"Add",
    EDITCUSTOMER:"Edit",
    VIEWCUSTOMER:"View",
    ADD_CUSTOMERS:"Add Customer",
    CREDITTERMSCUSTOMER:"Customer Credit Terms",
    DOWNLOAD_PDF:"downloaded_file.pdf",
    QUOTATION:"Quotation",
    ADD_QUOTATION:"+ Add Quotation",
    NO_QUOTATION:"No quotations are available",
    CURRENT_QUOTATION:"Your Current Quotations",
    VIEW_QUOTATION:"View Quotations",
    QUOTATION_INFO:"Quotations Personal Info",
    ADDQUOTATION:"Add",
    EDITQUOTATION:"Edit",
    VIEWQUOTATION:"View",
    ADD_QUOTATION_TITLE:"Add Quotation",
    CREDITTERMSQUOTATION:"Quotations Credit Terms",
    SNO:"#",
    SELECT_PRODUCT:"Select Product",
    PRODUCT_DESCRIPTION:"Product Description",
    HSN_CODE:"HSN Code",
    QUANTITY:"Quantity",
    UNIT:"Unit",
    RATE_PER_ITEM:"Rate Per Item",
    TAXES:"Taxes",
    AMOUNT:"Amount",
    ACTIONS:"Action",
    CUSTOMER_NOTESLABEL:"Customer Notes",
    TERMS_AND_CONDITION:"Terms and Conditions",
    CGST:"CGST in %",
    SGST:"SGST in %",
    TOTAL_AMOUNT:"Total amount without taxes:",
    ROUND_OFF:"Round Off:",
    TOTAL:"Grand Total:",
    CUSTOMER_REQ:"Customer is required",
    CURRENCY_REQ:"Currency is required",
    QUOTATIONDATE_REQ:"Quotation date is required",
    QUOTATIONEXPIRY_DATE:"Expiry date is required",
    ADDED_SUPPLIER:"Supplier Added Successfully",
    EDITED_SUPPLIER:"Supplier Edited Succesfully",
    DELETE_SUPPLIER:"Supplier Deleted Successfully",
    CUSTOMER_MODEL:"Customer",
    SUPPLIER_MODEL:"Supplier",
    CUSTOMER_ADDED:"Customer Added Successfully",
    CUSTOMER_DELETE:"Customer Deleted Successfully",
    EDITED_CUSTOMER:"Customer Edited Successfully",
    CONVERT_INVOICE:"Convert to Invoice",
    DOWNLOAD:"Download",
    SHARE:"Share",
    EDITED_QUOTATION:"{code} edited Successfully",
    ADDING_QUOTATION: "{code} added successfully",
    QUOTATION_AUTO_GENERATE:"Set Quotation Number Preferences",
    MANUAL_ENTER:"Your quotation numbers are currently set to auto-generate to save time",
    CONFIRM:"Confirm",
    QUOTATION_DETAILS:"Quotation Details",
    QUOTATION_NUMBER:"Quotation Number:",
    CUSTOMER_NAME:"Customer Name:",
    STATUS:"Status:",
    QUOTATION_DATE:"Quotation Date:",
    QUOTATION_DELETE: "{code} deleted successfully",
    QUOTE_SHARE_SUCCESS:"is shared successfully",
    QUOTE_SHARE_FAILED:"is shared failed",
    QUOTATION_STATUS:"Quotation Status:",
    EXPIRY:"Expiry Date:",
    YOUR_MODEL_EDITABLE:"Your quotation number is manually editable.",
    QUOTATION_PREFERENCE:"Set Quotation Number Preferences",
    EDIT_QUOTATION_NUMBER:"Edit Quotation Number",
    FOOTER_TXT: "All Rights Reserved by ",
    PHEONIX_TXT: "Pheonix solutions",
    TERM_TEXT: "Terms and Conditions |  Privacy  Policy | Security Policy",
    DISCARD:" Are you sure you want to leave this page?",
    DISCARD_INFORMATION:"  All unsaved changes will be lost and cannot be recovered!",
    LEAVE:"Leave",
    STAY_ON_PAGE:"Stay on Page",
    URL:"https://www.pheonixsolutions.com/",
    POLICY:"https://www.pheonixsolutions.com/privacy-policy",
    POLICY_TERM:"https://www.pheonixsolutions.com/privacy-policy",
    SECURITY_POLICY:"https://www.pheonixsolutions.com/security-policy",
    EDIT:"Edit Product",
    ALERT:"Alert: Sales Price is Lower than Purchase Price!"

   
};

  export const ERROR_MESSAGES = {
    ENTER_BOTH: "Please enter both email and password",
    VALID_EMAIL: "Please enter a valid email address",
    VALID_PHONE: "Phone number must be exactly 10 digits",
    VALID_PASSWORD: "Please enter password",
    PWD_LENGTH: "Password should be minimum of 8 characters",
    INVALID_FILE: "Invalid file type. Please upload a CSV file.",
    UNKNOWN_ERROR:"An unknown error occurred.",
    PRODUCT_NAME:"Product not exist",
    OPENING_STOCK:"Opening stock is required",
    DOWNLOAD_FILE: "Error downloading file:",
    FAILED_UPDATE:"Failed to update product",
    NETWORK_ERROR:"Network error:",
    SUPPLIER_NOTFOUND:"supplier is not found",
    FAILED_TODELETE:"Failed to delete supplier",
    INVALID_EMAIL: "Invalid email format",
    PHONE_NUMBER:"Phone number must be 10 digits",
    ALTER_NUMBER:"Alternative phone number must be 10 digits",
    OPEN_BALANCE:"Opening balance must be a number",
    GRACE_PERIOD:"Grace period must be a number",
    CREDIT_LIMIT:"Credit limit must be a number",
    PAYMENT_PERIOD:"Payment period must be a number",
    SUPPLIER_NAME:"Supplier name already exists",
    UNKNOW:"Unknown error",
    FAILED_SAVE:"Failed to save supplier. Please try again",
    CREDIT_TERMREQ:"Credit Term Date is required",
    DATE_FORMATE:"Invalid date format",
    PAYMENT_PERIODREQ:"Payment Period is required",
    VAILD_NUMBER:"Must be a valid number",
    ZERO:"Must be greater than 0",
    EXCEED:"Cannot exceed 365 days",
    WHOLE_NUMBER:"Must be a whole number",
    NEGATIVE:"Cannot be negative",
    VALID_PHONENUMBER :  "Alternate phone number should not be the same as the primary phone number",
    VALID_ALTERNUMBER :  "Phone number should not be the same as the alternative phone number",
    GST_NO_IS_REQUIRED: "GST no is required",
    GST_NO_SHOULD_1: "GST no should be 15 characters",
    GST_NO_SHOULD: "GST no should contain only alphanumeric characters",
    GST_BOTH:"GST number must contain both letters and numbers",
    DELETE:"Customer data is null or missing an ID.",
    SUPPLIER_NO:"Alternate phone number must be different from the primary phone number.",
    SUPPLIER_ALTERNO:"Phone number must be different from the alternative phone number.",
    CUSTOMER_NAME:"Customer name should contain only alphabets",
    NAME_SUPPLIER:"Supplier name should contain only alphabets",
    NUMBER_LENGTH: "Phone number must be at least 7 digits long",
    NUMBER_MAX: "Phone number must not exceed 15 digits",
    NUMB_INVALID: "Phone number contains invalid characters",
    NUMB_LETTERS: "Phone number contains letters, which is not allowed",
    NUMB_SPCL: "Phone number contains special characters that are not allowed",
    SUPPLIER_PHONE:"Phone number not valid",
    SELECTPRODUCT:"Please select a product.",
    DUPLICATE:"This quotation number is duplicate.Kindly enter a unique quotation number",
    DUPLICATEPRODUCT:"This product is already added to the table.",
    VALIDQTY:"Please enter valid quantity.",
    FAILED:"Failed to update quotation",
    UNKNOWN:"Unknown error",
    NETWEOKERROR:"A network error occurred. Please try again.",
    QUOTATION_EMPTY:"Quotation number cannot be empty",
    QUOTATION_EXIST:"Quotation number already exists",
    QUOTATION_AVAILABLE:"Quotation number is available and valid",
    QUOTATION_VALIDATION:"Error validating quotation number",
    QUOTATION_ERROR_VALIDATE:"Error validating Quotation:",

  
  };

  export const pdfdata = {
    From: `
    Pheonix Solutions,
    1/72A, Pacha Goundan Palayam,
    PeriyaVeeraSangili(PO),
    Vijayamangalam-638056,
    Erode.
    
    GSTIN: 33BJYPD0540E1ZI`,
  };
  


  export const baseurl = {
  DEV_URL: "https://dev-api-taxary.pheonixsolutions.com/graphql",
  UAT_URL: "https://uat-api-taxary.pheonixsolutions.com/graphql",
  DEMO_URL:"https://demo.api-taxary.net/graphql",
  PROD_URL:"https://api-taxaryapp.aaleepheonixsolutions.com/graphql",
  LOCAL_URL: "http://localhost:4000/graphql",
  }