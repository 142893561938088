import React, { ChangeEvent, FocusEvent, ReactNode } from "react";
import TextField from "@mui/material/TextField";
import PheonixTextFieldProps from "../interfaces/PheonixTextFieldProps";

const PheonixTextField: React.FC<PheonixTextFieldProps> = ({
  id,
  type,
  value,
  placeholder,
  label,
  variant,
  disabled,
  onChange,
  name,
  onFocus,
  onBlur,
  endAdornment,
  style,
  required,
  multiline,
  rows,
  rememberMe,
  className,
  error,
  helperText,
  sx,
  InputProps,
  maxRows,

}) => {
  return (
    <TextField
      id={id}
      type={type}
      value={value}
      name={name}
      placeholder={placeholder}
      label={label}
      variant={variant}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: endAdornment,
        className: className,
        style: style,
        ...InputProps, 
      }}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
      required={required}
      sx={{
       
        "& label.Mui-focused": {
          color: "#152726",
          backgroundColor:"#A9E7CB",
          
        },
        "& .MuiInputBase-input": {
      fontFamily: "Lexend", 
      color: "black", 
    },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#152726",
        },
        "& .MuiInputLabel-root": {
          color: "#032C43",
          fontFamily: "Lexend",
        },
        "& .MuiInputLabel-outlined": {
          color: "#032C43",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#152726!important",
    
          },
        },
        "& .MuiInputBase-root": {
          backgroundColor:"#A9E7CB",
    },
    ...sx,
      }}

    />
  );
};
export default PheonixTextField;
