import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PheonixBox } from "pheonixui";
import {
  useMediaQuery,
  Typography,
  Autocomplete,
  TextField,
  Box,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
  ListSubheader,
  Paper,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import PheonixTextField from "../Components/PheonixTextField";
import PheonixButton from "../Components/PheonixButton";
import { TEXT_MESSAGES } from "../const";
import { EDIT_STAFF_MUTATION, ADD_STAFF_MUTATION } from "../graphql/query";
import baseUrl from "../api";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { PheonixAccess } from "../Components/PheonixAccesslevel";
import PheonixPopup from "../Components/PheonixPopup";

interface AddStaffProps {
  onClose: () => void;
  onSave: (data: any) => void;
  staffData?: any;
  viewMode?: boolean;
  editMode?: boolean;
  staffId?: string;
}

interface Product {
  id: string;
  name: string;
}

const AddStaff: React.FC<AddStaffProps> = ({
  onClose,
  onSave,
  staffData,
  viewMode = false,
  editMode = false,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const [gst, setGST] = useState<string | null>(null);
  const [unit, setUnit] = useState<string | null>(null);
  const [staffname, setStaffname] = useState<string>("");
  const [staffid, setStaffid] = useState<string>("");
  const [role, setRole] = useState<string | null>(null);
  const [error, setError] = useState({
    staff_name: false,
    staff_id: false,
    role: false,
    access_level: false,
  });
  const [isAccessLevelDialogOpen, setIsAccessLevelDialogOpen] = useState(false);
  const [selectedAccessLevels, setSelectedAccessLevels] = useState<
    { module: string; permissions: string[] }[]
  >([]);

  const [selectedModule, setSelectedModule] = useState<string | null>(null);
  const [popup,setPopup]=useState(false)

  const roles = [
    { value: "Admin", label: "Admin" },
    { value: "User", label: "User" },
    { value: "Billing", label: "Billing" },
  ];
  const Accesslevel = [
    { value: "Quotation", label: "Quotation" },
    { value: "Invoice Sales", label: "Invoice Sales" },
    { value: "Invoice Purchase", label: "Invoice Purchase" },
    { value: "Supplier Payment", label: "Supplier Payment" },
    { value: "Sales Payment", label: "Sales Payment" },
    { value: "Stock Report", label: "Stock Report" },
    { value: "Purchase Report", label: "Purchase Report" },
    { value: "Sales Report", label: "Sales Report" },
    { value: "Product", label: "Product" },
    { value: "Stock", label: "Stock" },
    { value: "Staff", label: "Staff" },
  ];
  useEffect(() => {
    if (staffData) {
      setStaffname(staffData.staff_name || "");
      setStaffid(staffData.staff_id || "");
      setRole(staffData.role || null);
      setSelectedAccessLevels(
        staffData.access_level?.map(
          (level: { module: string; permissions: string[] }) => ({
            module: level.module,
            permissions: level.permissions || [],
          })
        ) || []
      );
    }
  }, [staffData]);

  const handleSave = async () => {
    let hasError = false;
    const newErrors = {
      staff_name: !staffname,
      staff_id: !staffid,
      role: !role,
      access_level: selectedAccessLevels.length === 0,
    };

    setError(newErrors);
    hasError = Object.values(newErrors).some((error) => error);
    if (hasError) return;

    const accessLevelInput = selectedAccessLevels.map((level) => ({
      module: level.module,
      permissions: level.permissions,
    }));

    const variables = {
      staff_name: staffname,
      staff_id: staffid,
      role,
      access_level: accessLevelInput,
    };

    try {
      const response = await axios.post(baseUrl, {
        query: editMode
          ? EDIT_STAFF_MUTATION.loc?.source.body
          : ADD_STAFF_MUTATION.loc?.source.body,
        variables: editMode ? { id: staffData?.id, ...variables } : variables,
      });

      const result = editMode
        ? response.data.data?.editStaff
        : response.data.data?.addStaff;
      if (result?.statusCode === 200 || result?.statusCode === 201) {
        onSave(result.staff);
        onClose();
      } else {
        setError(result?.message || "Unknown error");
      }
    } catch (error: any) {
      console.error("Network error:", error.message || error);
    }
  };

  return (
    <PheonixBox
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        paddingX: "10px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "24px",
        border: "1px solid",
        borderColor: "rgba(224,224,224,1)",
        height: "70vh",
        position: "relative",
        boxSizing: "border-box",
      }}
    >
      <PheonixBox
        sx={{
          display: "flex",
          width: isSmallScreen ? "auto" : isMediumScreen ? "auto" : "856px",
          flexDirection: isSmallScreen
            ? "column"
            : isMediumScreen
            ? "column"
            : "row",
          gap: isSmallScreen ? "16px" : isMediumScreen ? "16px" : "128px",
          overflow: "auto",
          marginTop: "20px",
          height: "auto",
          padding: isSmallScreen ? "16px" : "26px",
          alignItems: "stretch",
        }}
      >
        <PheonixBox
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <PheonixTextField
            label="Staff ID"
            type="text"
            variant="standard"
            required
            error={error.staff_id}
            helperText={error.staff_id ? "staff_id is required" : ""}
            value={staffid}
            disabled={viewMode}
            onChange={(e) => {
              setStaffid(e.target.value);
              setError((prev) => ({ ...prev, staff_id: !e.target.value }));
            }}
            sx={{
              width: isSmallScreen ? "302px" : "348px",
              height: "48px",
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
              },
              "& .MuiInputLabel-root": {
                color: "gray",
                fontFamily: "Lexend",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "gray",
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />

          <Autocomplete
            options={roles}
            disabled={viewMode}
            getOptionLabel={(option) => option.label}
            value={roles.find((option) => option.value === role) || null}
            onChange={(_, newValue) => {
              setRole(newValue?.value || null);
              setError((prev) => ({ ...prev, unit: !newValue }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={error.role}
                helperText={error.role ? "Role is required" : ""}
                label="Role"
                variant="standard"
              />
            )}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "preventOverflow",
                    options: {
                      boundary: "window",
                    },
                  },
                ],
              },
            }}
            PaperComponent={({ children }) => (
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
                  backgroundColor: "#fff",
                  fontFamily: "Lexend",
                }}
              >
                {children}
              </div>
            )}
            sx={{
              width: isSmallScreen ? "302px" : "348px",
              height: "48px",
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
                fontFamily: "Lexend",
              },
              "& .MuiInputLabel-root": {
                color: "gray",
                fontFamily: "Lexend",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "gray",
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />
        </PheonixBox>
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <PheonixTextField
            label="Staff Name"
            type="text"
            variant="standard"
            disabled={viewMode}
            value={staffname}
            error={error.staff_name}
            helperText={error.staff_name ? "Staff Name is required" : ""}
            onChange={(e) => {
              setStaffname(e.target.value);
              setError((prev) => ({ ...prev, staff_name: !e.target.value }));
            }}
            required
            sx={{
              width: isSmallScreen ? "302px" : "348px",
              height: "48px",
              "& .MuiInputBase-root": {
                fontFamily: "Lexend",
              },
              "& .MuiInputLabel-root": {
                color: "gray",
                fontFamily: "Lexend",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "gray",
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />
          <Autocomplete
            options={Accesslevel}
            disablePortal
            disabled={viewMode}
            getOptionLabel={(option) => option.label}
            value={
              selectedAccessLevels.length > 0
                ? Accesslevel.find(
                    (option) => selectedAccessLevels[0]?.module === option.value
                  ) || null
                : null
            }
            onChange={(_, newValue) => {
              if (newValue) {
                setSelectedModule(newValue.label);
                setSelectedAccessLevels([
                  { module: newValue.value, permissions: [] },
                ]);
                setIsAccessLevelDialogOpen(true);
              } else {
                setSelectedAccessLevels([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={error.access_level}
                helperText={
                  error.access_level ? "Access Level is required" : ""
                }
                label="Access Level"
                variant="standard"
              />
            )}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "preventOverflow",
                    options: {
                      boundary: "window",
                    },
                  },
                ],
              },
            }}
            PaperComponent={({ children }) => (
              <Paper
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "11px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
                  backgroundColor: "#fff",
                  fontFamily: "Lexend",
                  padding: "8px",
                }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                {children}
              </Paper>
            )}
            ListboxProps={{
              style: {
                maxHeight: "none",
                overflowY: "visible",
              },
            }}
            sx={{
              width: isSmallScreen ? "302px" : "348px",
              height: "48px",
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
                fontFamily: "Lexend",
              },
              "& .MuiInputLabel-root": {
                color: "gray",
                fontFamily: "Lexend",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "gray",
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />
        </Box>
      </PheonixBox>
      <Box
        sx={{
          position: "absolute",
          top: isSmallScreen ? "80%" : isMediumScreen ? "90%" : "85%",
          paddingX: "16px",
          paddingY: "16px",
          bottom: 0,
          width: isSmallScreen ? "80%" : isMediumScreen ? "80%" : "90%",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "8px",
          }}>
          <PheonixButton
            label={TEXT_MESSAGES.BACK_BUTTON}
            width={"90px"}
            backgroundColor={"white"}
            borderColor={"black"}
            border="1px solid"
            color={"secondary"}
            variant="outlined"
            onClick={onClose}
            sx={{
              color: "black",
              transform: "none",
              textTransform: "none",
              "&:hover": { borderColor: "black", backgroundColor: "white" },
            }}/>
          {!viewMode && (
            <PheonixButton
              label={TEXT_MESSAGES.SAVE_BUTTON}
              variant="outlined"
              width={"90px"}
              onClick={handleSave}
              sx={{
                backgroundColor: "black",
                color: "white",
                transform: "none",
                textTransform: "none",
                "&:hover": { backgroundColor: "black" },
              }}
            />
          )}
        </Box>
      </Box>
      <PheonixAccess
        open={isAccessLevelDialogOpen}
        onOpenChange={setIsAccessLevelDialogOpen}
        selectedModule={selectedModule || "Access Level"}
        onSave={(permissions) => {
          if (!selectedModule) return;
          setSelectedAccessLevels((prev) => {
            const updatedLevels = prev.filter(
              (level) => level.module !== selectedModule
            );
            updatedLevels.push({
              module: selectedModule as string,
              permissions,
            });
            return updatedLevels;
          });
        }}
      />
    </PheonixBox>
  );
};

export default AddStaff;
