import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography } from "@mui/material";
import { TEXT_MESSAGES } from "../const";
import PheonixFooterProps from "../interfaces/PheonixFooterProps";

const PheonixFooter: React.FC = () => {
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      component="footer"
      sx={{
        position: "relative",
        width: "100%",
        backgroundColor: "white",
        borderTop: "1px solid #ddd",
        boxShadow: "0px -2px 5px rgba(0,0,0,0.1)",
        padding: "10px 20px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: isMobile ? "center" : "space-between",
        alignItems: "center",
        fontSize: "12px",
        fontFamily: "Lexend",
        color: "#212121",
        paddingLeft: isMobile ? "35px" : isTablet ? "100px" : "280px",
        transition: "padding-left 0.3s ease-in-out",
        boxSizing: "border-box",
        textAlign: isMobile ? "center" : "left",
      }}
    >
      <span
         style={{
          paddingLeft: isMobile ? "0px" :"",
          width: isMobile? "100%":"",
          paddingRight: isMobile ? "15px" : "",
        }}>
        &copy; {currentYear} {TEXT_MESSAGES.FOOTER_TXT}{" "}
        <a
          href={TEXT_MESSAGES.URL}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            fontWeight: 400,
            fontFamily: "Lexend",
            color: "#000000",
          }}
        >
          {TEXT_MESSAGES.PHEONIX_TXT}
        </a>
      </span>
      <div style={{ display: "flex", gap: "15px" }}>
        <a
          href={TEXT_MESSAGES.POLICY}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#212121",
            textDecoration: "none",
            fontWeight: 400,
            fontFamily: "Lexend",
          }}
        >
          {TEXT_MESSAGES.TERM_TEXT}
        </a>
        <a
          href={TEXT_MESSAGES.POLICY_TERM}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#212121",
            textDecoration: "none",
            fontWeight: 400,
            fontFamily: "Lexend",
          }}
        ></a>
        <a
          href={TEXT_MESSAGES.SECURITY_POLICY}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#212121",
            textDecoration: "none",
            fontWeight: 400,
          }}
        ></a>
      </div>
    </Box>
  );
};
export default PheonixFooter;
