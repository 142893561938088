import React, { useState, useEffect } from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { TEXT_MESSAGES } from "../const";
import { GridColDef } from "@mui/x-data-grid";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import themestyle from "../theme";
import EditIcon1 from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PheonixPaper from "../Components/PheonixPaper";
import ModalFormData from "../interfaces/ModalFormDataProps";
import baseUrl from "../api";
import axios from "axios";
import { GET_STOCK_QUERY, DELETE_STOCK } from "../graphql/query";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";
import PheonixButton from "../Components/PheonixButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import product from "../assets/product.png";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import AddStock from "./Addstock";
import { PheonixSnackbar } from "pheonixui";
import EditConfirmationDialog from "./Editmodel";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DeleteModal from "../Components/PheonixDeletemodel";
import EditStock from "./Editstock";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import ImportStockFile from "../Components/ImportStockCSV";
import PheonixPopup from "../Components/PheonixPopup";

const StockManagement: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const [showaddStock, setShowaddStock] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<"add" | "edit" | "delete" | "">("");
  const [snackbarTypecolor, setSnackbarTypecolor] = useState<"add" | "edit" | "delete" | "">("");
  const [alertmessage, setalertmessage] = useState("");
  const [viewopen, setViewOpen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [dataLoading, setdataLoading] = useState(false);
  const [tableData, setTableData] = useState<ModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [breadcrumbText, setBreadcrumbText] = useState<string>("");
  const [currentView, setCurrentView] = useState<"list" | "view" | "edit" | "addstock">("list");
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedStock, setSelectedStock] = useState<any | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [entityToEdit, setEntityToEdit] = useState<string | null>(null);
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [popup,setPopup]=useState(false)

  

  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: stockRefetch,
  } = useQuery(GET_STOCK_QUERY, {
    variables: {
       page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery,
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, stock } = data.getStocksperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const stocksWithSerialNo = stock.map(
        (stock: ModalFormData, index: number) => ({
          ...stock,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(stocksWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });
  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, stock } = customerData.getStocksperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const stocksWithSerialNo = stock.map(
        (stock: ModalFormData, index: number) => ({
          ...stock,
          serial_no: (startIndex + index).toString(),
        })
      );

      setTableData(stocksWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      stockRefetch({ variables: { searchQuery: {} } });
    } else {
      stockRefetch();
    }
  };
  const handlePaginationChange = (newModel: {
    page: number;
    pageSize: number;
  }) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: newModel.page,
      pageSize: newModel.pageSize,
    }));
  };
 useEffect(() => {
  stockRefetch({
       variables: {
         page: paginationModel.page,
         perPage: paginationModel.pageSize,
         searchQuery: searchQuery.trim() === "" ? undefined : searchQuery,
       },
     });
   }, [searchQuery, paginationModel.page, paginationModel.pageSize]);

  const handleAddStock = () => {
    setCurrentView("addstock");
    setBreadcrumbText(TEXT_MESSAGES.STOCK_BREAD);
    setShowaddStock(true);
  };
  const handleSave = () => {
    setShowaddStock(false);
    setCurrentView("list");
    setBreadcrumbText("Stock Listing");
    setalertmessage(TEXT_MESSAGES.ADDING_STOCK);
    handleSnackbarOpen("add");
    stockRefetch();

  };

  const handleEditConfirmation = (selectedRow: ModalFormData) => {
    setIsEditDialogOpen(false);
    setSelectedStock(selectedRow);
    setBreadcrumbText("Edit Stock");
    setCurrentView("edit");
    setIsEditing(true);
  };
  const handleCancelEdit = () => {
    setEditopen(false);
    setCurrentView("list");
    setBreadcrumbText("Stock Listing");
  };

  const handleEdit = () => {
    setIsEditDialogOpen(true);
  };

  const handleView = (selectedRow: ModalFormData) => {
    setSelectedStock(selectedRow);
    setBreadcrumbText("View Stock");
    setCurrentView("view");
  };

  const handleOpenImportModal = () => {
    setImportModalOpen(true);
  };

  const handleCloseImportModal = () => {
    setImportModalOpen(false);
    stockRefetch();
  };

  const handleSnackbarOpen = (type: "add" | "edit" | "delete") => {
    setSnackbarType(type);
    setSnackbarTypecolor(type);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
      setSnackbarType("");
      setSnackbarTypecolor("");
    }, 3000);
  };
  const handleSaveEdit = () => {
    setEditopen(false);
    setBreadcrumbText("Stock Listing");
    setCurrentView("list");
    handleSnackbarOpen("edit");
    setalertmessage(TEXT_MESSAGES.EDITED_PRODUCT);
    stockRefetch();
  };
 
  const handleDeleteClick = (id: string, stock: ModalFormData) => {
    setEntityToDelete(id);
    setEntityToDisplay(stock.product_name);
    setSelectedStock(stock);
    setDeleteModalOpen(true);
  };

  const handleDeleteStock = async (stock: ModalFormData | null): Promise<void> => {
    if (!stock || !stock.id) {
      console.error("Stock data is null or missing an ID.");
      return;
    }

    try {
      const response = await axios.post(baseUrl, {
        query: DELETE_STOCK.loc?.source.body,
        variables: { id: stock.id },
      });

      const { statusCode, message } = response.data.data.deleteStock;
      if (statusCode === 200) {
        setTableData((prev) => prev.filter((p) => p.id !== stock.id));
        setalertmessage(TEXT_MESSAGES.DELETE_PRODUCT);
        setDeleteModalOpen(false);
        handleSnackbarOpen("delete");
        stockRefetch();
      } else {
        alert(message || "Failed to delete stock.");
      }
    } catch (error: any) {
      alert(`Error: ${error.message}`);
    }
  };
  const handleBreadcrumbClick = (breadcrumb: string) => {
    if (breadcrumb === "stocks" && (currentView !== "list")) {
      setPopup(true)
    }
  };
  const handlePopupclose=()=>{
    setCurrentView("list");
    setShowaddStock(false)
    setBreadcrumbText("Stock listing")
    setPopup(false)
  
  }

  const columns: GridColDef[] = [
    {
      field: "entrydate",
      headerName: "Entry Date",
      minWidth: 250,

      renderCell: (params: any) => {
        if (!params.row?.entrydate) return "No Date";

        const date = params.row.entrydate instanceof Date ? params.row.entrydate : new Date(params.row.entrydate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
    },
    { field: "product_name", headerName: "Product Name", width: 350 },
    {
      field: "quantity",
      headerName: "Current Stock",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        const stockValue = params.value || 0;
        const stockUnit = params.row.unit || "";
        return <span>{`${stockValue} ${stockUnit}`}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-8px",
          }}
        >
          <IconButton onClick={() => handleView(params.row)}>
            <RemoveRedEyeSharpIcon
              style={{
                width: "20px",
                height: "20px",
                color: "black",
                marginTop: "7px",
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteClick(params.row.id, params.row)}
          >
            <DeleteIcon
              style={{
                width: "20px",
                height: "20px",
                color: "black",
                marginTop: "7px",
              }}
            />
          </IconButton>
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: isSmallScreen ? "15px 25px" : "15px 30px",
            marginLeft: "-12px",
          }}
        >
          <Typography
            style={{
              color: themestyle.colors.black,
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isSmallScreen
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
                  marginBottom: "8px"
            }}
          >
            {TEXT_MESSAGES.STOCKS}
          </Typography>
          <PheonixBreadcrumbs breadcrumbText={breadcrumbText}  onBreadcrumbClick={handleBreadcrumbClick}/>
        </div>
        {currentView === "view" && (
          <PheonixButton
            label={
              isSmallScreen ? (
                <EditIcon1 style={{ height: "24px", width: "24px" }} />
              ) : (
                TEXT_MESSAGES.EDIT_BUTTON
              )
            }
            width={isSmallScreen ? "43px" : "90px"}
            startIcon={
              !isSmallScreen ? (
                <EditIcon1 style={{ height: "24px", width: "24px" }} />
              ) : null
            }
            sx={{
              background: themestyle.colors.Primary2,
              color: themestyle.colors.white,
              minWidth: isSmallScreen ? "30px" : "90px",
              marginRight: "20px",
              marginTop: "10px",
              transform: "none",
              textTransform: "none",
            }}
            onClick={handleEdit}
          ></PheonixButton>
        )}

        {currentView === "list" && tableData.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: isSmallScreen
                  ? "85px"
                  : isMediumScreen
                  ? "340px"
                  : "401px",
                height: isSmallScreen ? "32px" : "43px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: "nowrap",
                gap: isSmallScreen ? "13px" : isMediumScreen ? "15px" : "32px",
                padding: isMediumScreen ? "16px" : "0",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <PheonixButton
                label={
                  isSmallScreen ? (
                    <ListAltIcon style={{ width: "24px", height: "24px" }} />
                  ) : (
                    TEXT_MESSAGES.ADD_STOCK
                  )
                }
                style={{
                  background: themestyle.colors.Primary2,
                  color: themestyle.colors.white,
                  width: isSmallScreen ? "auto" : "150px",
                  minWidth: isSmallScreen ? "40px" : "150px",
                  height: "43px",
                  fontWeight: 500,
                  fontSize: "16px",
                  transform: "none",
                  textTransform: "none",
                  padding: isSmallScreen ? "5px" : "10px",
                }}
                onClick={handleAddStock}
              ></PheonixButton>
              <PheonixButton
                label={
                  isSmallScreen ? (
                    <FilePresentIcon
                      style={{ height: "24px", width: "24px" }}
                    />
                  ) : (
                    TEXT_MESSAGES.IMPORT_PRODUCT
                  )
                }
                startIcon={
                  !isSmallScreen ? (
                    <FilePresentIcon
                      style={{ height: "24px", width: "24px" }}
                    />
                  ) : null
                }
                style={{
                  background: themestyle.colors.Primary2,
                  color: themestyle.colors.white,
                  width: isSmallScreen ? "30px" : "219px",
                  minWidth: isSmallScreen ? "40px" : "219px",
                  height: "43px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 500,
                  fontSize: "16px",
                  textTransform: "none",
                  marginRight: "15px",
                  borderRadius: "4px",
                  flexShrink: 0,
                }}
                onClick={handleOpenImportModal}
              >
                {" "}
              </PheonixButton>
              {isImportModalOpen && (
                <ImportStockFile
                  isOpen={isImportModalOpen}
                  onClose={handleCloseImportModal}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <Box sx={{ padding: "15px" }}>
        {showaddStock ? (
          <AddStock
            onClose={() => {
              setCurrentView("list");
              setViewOpen(false);
              setBreadcrumbText("Stock Listing");
              setShowaddStock(false);
            }}
            onSave={handleSave}
          />
        ) : currentView === "edit" ? (
          <AddStock
            onClose={handleCancelEdit}
            onSave={handleSaveEdit}
            stockData={selectedStock}
            editMode={true}
          />
        ) : currentView === "view" ? (
          <Box style={{ width: "100%", height: "100vh" }}>
            <AddStock
              onClose={() => {
                setCurrentView("list");
                setViewOpen(false);
              }}
              stockData={selectedStock}
              viewMode={true}
              onSave={(updatedProduct: ModalFormData) => {
                setCurrentView("view");
                stockRefetch();
              }}
            />
          </Box>
        ) : (
          <PheonixPaper>
            {tableData.length === 0 ? (
              <div style={{ width: "80%", position: "fixed" }}>
                <PheonixDataGrid
                  columns={[]}
                  rows={[]}
                  rowCount={tableData.length}
                  showPagination={tableData.length > 0}
                  sx={{
                    "& .MuiDataGrid-footerContainer": {
                      visibility: "hidden",
                    },
                    "& .MuiButtonBase-root.MuiButton-root": {
                      color: "#49955E",
                    },
                  }}
                  customEmptyContent={
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          mb: 2,
                          textAlign: isSmallScreen ? "center" : "left",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: isSmallScreen ? "14px" : "16px",
                          }}
                        >
                          {TEXT_MESSAGES.CURRENT_STOCK}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 500,
                            fontSize: isSmallScreen ? "14px" : "16px",
                          }}
                        >
                          {tableData.length} {TEXT_MESSAGES.AVAILABLE_SERVICE}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                          width: "100%",
                          maxWidth: isMediumScreen ? "450px" : "100%",
                          minHeight: isMediumScreen ? "50vh" : "60vh",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            mb: 2,
                            fontSize: isSmallScreen ? "14px" : "16px",
                          }}
                        >
                          {TEXT_MESSAGES.NO_STOCK}
                        </Typography>
                        <PheonixButton
                          label={TEXT_MESSAGES.ADD_STOCK}
                          width={"auto"}
                          endIcon={
                            <OpenInNewIcon sx={{ color: "white", width: 23 }} />
                          }
                          variant="contained"
                          onClick={handleAddStock}
                          sx={{
                            background: themestyle.colors.Primary2,
                            color: themestyle.colors.white,
                            textTransform: "none",
                            marginBottom: 2,
                            borderRadius: "16px",
                            width: isSmallScreen ? "90%" : "auto",
                            "&:hover": {
                              background: themestyle.colors.Primary2,
                              color: themestyle.colors.white,
                            },
                          }}
                        ></PheonixButton>

                        <br></br>
                        <Box
                          component="img"
                          src={product}
                          alt="Product"
                          sx={{
                            width: isSmallScreen
                              ? "120px"
                              : isMediumScreen
                              ? "140px"
                              : "150px",
                            height: isSmallScreen
                              ? "130px"
                              : isMediumScreen
                              ? "150px"
                              : "161px",
                            maxWidth: "100%",
                          }}
                        />
                      </Box>
                    </Box>
                  }
                />
              </div>
            ) : (
              <div style={{ width: "100%", overflowX: "auto" }}>
                <PheonixDataGrid
                  columns={columns}
                  pageSizeOptions={[5, 10, 20, 30]}
                  style={{ border: "0px", marginLeft: "20px" }}
                  rows={tableData}
                  isShownAlert={false}
                  loading={dataLoading}
                  rowCount={tableCount}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  showPagination={currentView === "list"}
                  searchQuery={searchQuery}
                  onSearchQueryChange={handleSearchQueryChange}
                  onPaginationModelChange={handlePaginationChange}
                  getRowId={(row) =>
                    row.id || `${paginationModel.page}-${row.serial_no}`
                  }
                  sx={{
                    "& .MuiButtonBase-root.MuiButton-root": {
                      color: "#49955E",
                    },
                    width: "auto",
                  }}
                />
              </div>
            )}
          </PheonixPaper>
        )}
        <PheonixSnackbar
          vertical="top"
          horizontal="right"
          open={snackbarOpen}
          onClose={() => {
            setSnackbarOpen(false);
          }}
          message={alertmessage}
          severity="success"
          backgroundColor={
            snackbarType === "add"
              ? "#2E7D32"
              : snackbarType === "edit"
              ? "#EDF7ED"
              : "#D32F2F29"
          }
          color={
            snackbarTypecolor === "add"
              ? "#FFFFFF"
              : snackbarTypecolor === "edit"
              ? "#1E4620"
              : "#663C00"
          }
          sx={{
            fontFamily: "Lexend",
            top: "170px",
            fontWeight: 500,
            fontSize: "14px",
            mt: isSmallScreen ? 15 : isMediumScreen ? 18 : 12,
            height: "35px",
            paddingTop: "3px",
            paddingBottom: "6px",
            width: isSmallScreen ? "90%" : "auto",
            maxWidth: "250px",
            minWidth: "150px",
            whiteSpace: "nowrap",
          }}
        />
        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={() => handleDeleteStock(selectedStock)}
          entity={entityToDisplay}
        />
        <EditConfirmationDialog
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          productName={selectedStock?.product_name || ""}
          onConfirm={handleEditConfirmation}
          selectedProduct={selectedStock}
         entityName={TEXT_MESSAGES.INFORMATION}
        />
         <PheonixPopup
        open={popup}
        onClose={handlePopupclose}
        onConfirm={()=>{setPopup(false)}}/>
      </Box>
    </div>
  );
};
export default StockManagement;
