import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SegmentIcon from "@mui/icons-material/Segment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Tooltip from "@mui/material/Tooltip";
import logo from "../assets/translogo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import SidebarItem from "../Components/PheonixSideBar";
import SidebarProps from "../interfaces/SideBarProps";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import PeopleIcon from "@mui/icons-material/People";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import CustomerIcon from "../assets/Vector (1).png";
import StaffIcon from "../assets/Vector (2).png";
import SalespaymentIcon from "../assets/Vector (3).png";
import ReceiptIcon from "@mui/icons-material/Receipt";

interface MenuItemType {
  icon: React.ReactNode;
  text: string;
  to: string;
  expandIcon?: React.ReactNode;
  expandedContent?: Array<{ icon: React.ReactNode; text: string; to: string }>;
}
const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedItems, setExpandedItems] = useState<{
    [key: string]: boolean;
  }>({});
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      onToggle();
    }
  };
  const toggleExpand = (itemText: any) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [itemText]: !prevState[itemText],
    }));
  };
  const handleProfile = () => {
    navigate("/profile");
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const handleMenuItemClick = (to: string) => {
    navigate(to);
    handleMenuClose();
  };
  const iconComponent = isOpen ? (
    <ChevronLeftIcon
      style={{
        width: "28px",
        height: "28px",
        color: "black",
        position: "absolute",
        left: "90%",
        top: 0,
      }}
    />
  ) : (
    <MenuIcon style={{ color: "black" }} />
  );

  const menuItems = [
    {
      icon: <DashboardIcon />,
      text: "Dashboard",
      to: "/home",
    },
    {
      icon: <DifferenceOutlinedIcon />,
      text: "Quotation",
      to: "/quotation",
    },
    {
      icon: <ReceiptIcon />,
      text: "Invoice",
      to: "",
      expandIcon: <ArrowDropDownIcon />,
      expandedContent: [
        {
          icon: <ShoppingBagOutlinedIcon />,
          text: "Purchase",
          to: "/purchase",
        },
        {
          icon: <RequestPageIcon />,
          text: "Sales",
          to: "/sales",
        },
      ],
    },
    {
      icon: <PaymentOutlinedIcon />,
      text: "Payment",
      to: "",
      expandIcon: <ArrowDropDownIcon />,
      expandedContent: [
        {
          icon: <PaymentsOutlinedIcon />,
          text: "Supplier Payment",
          to: "/supplierpayment",
        },
        {
          icon: <img src={SalespaymentIcon} alt="" />,
          text: "Sales Payment",
          to: "/salespayment",
        },
      ],
    },
    {
      icon: <AssessmentIcon />,
      text: "Report",
      to: "",
      expandIcon: <ArrowDropDownIcon />,
      expandedContent: [
        {
          icon: <SegmentIcon />,
          text: "Stock",
          to: "/stock",
        },
        {
          icon: <ShoppingCartIcon />,
          text: "Purchase",
          to: "/purchasereports",
        },
        {
          icon: <RequestPageIcon />,
          text: "Sales",
          to: "/salesreport",
        },
      ],
    },
    {
      icon: <PeopleIcon />,
      text: "Parties",
      to: "",
      expandIcon: <ArrowDropDownIcon />,
      expandedContent: [
        {
          icon: <ConnectWithoutContactIcon />,
          text: "Supplier",
          to: "/supplier",
        },
        {
          icon: <img src={CustomerIcon} alt="" />,
          text: "Customer",
          to: "/customer",
        },
      ],
    },
    {
      icon: <SettingsInputComponentIcon />,
      text: "Management",
      to: "",
      expandIcon: <ArrowDropDownIcon />,
      expandedContent: [
        {
          icon: <ListAltOutlinedIcon />,
          text: "Product",
          to: "/product",
        },
        {
          icon: <SegmentIcon />,
          text: "Stock",
          to: "/stocks",
        },
        {
          icon: <img src={StaffIcon} alt="" />,
          text: "Staff",
          to: "/staff",
        },
      ],
    },
  ];
  const listItemStyle = {
    backgroundColor: "#FFFFFF",
  };
  const selectedItemStyle = {
    backgroundColor: "#D5E3FF",
  };
  useEffect(() => {
    if (isMobile && isOpen) {
      const menuIconElement = document.getElementById("menuIcon");
      if (menuIconElement) {
        setMenuAnchorEl(menuIconElement);
      }
    }
  }, [isMobile, isOpen]);
  return (
    <>
      {isMobile ? (
        <>
          <div
            id="menuIcon"
            onClick={handleMenuClick}
            style={{
              position: "absolute",
              top: "15px",
              left: "15px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MenuIcon style={{ color: "black" }} />
          </div>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            {menuItems.map((item, index) => (
              <div key={index}>
                <MenuItem
                  onClick={() =>
                    item.expandIcon
                      ? toggleExpand(item.text)
                      : handleMenuItemClick(item.to)
                  }
                  sx={
                    item.to === location.pathname
                      ? selectedItemStyle
                      : listItemStyle
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      color: "#212121",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {item.icon}
                      <Typography
                        style={{
                          color: "#212121",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                      >
                        {item.text}
                      </Typography>
                    </div>
                    {item.expandIcon &&
                      (expandedItems[item.text] ? (
                        <ArrowDropUpIcon style={{ marginLeft: "auto" }} />
                      ) : (
                        <ArrowDropDownIcon style={{ marginLeft: "auto" }} />
                      ))}
                  </div>
                </MenuItem>
                {expandedItems[item.text] &&
                  item.expandedContent &&
                  item.expandedContent.map((subItem, subIndex) => (
                    <MenuItem
                      key={subIndex}
                      onClick={() => handleMenuItemClick(subItem.to)}
                      sx={
                        subItem.to === location.pathname
                          ? selectedItemStyle
                          : listItemStyle
                      }
                      style={{ paddingLeft: "50px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          color: "#212121",
                          fontWeight: 500,
                        }}
                      >
                        {subItem.icon}
                        <Typography
                          style={{ color: "#212121", fontWeight: 500 }}
                        >
                          {subItem.text}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
              </div>
            ))}
          </Menu>
        </>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: isOpen ? 260 : 65,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              background:
                "linear-gradient(194.32deg, #85E956 2.15%, #30BBC4 85.53%)",
              fontSize: "16px",
              color: "#FFFFFFB3",
              width: isOpen ? 265 : 57,
              boxSizing: "border-box",
              transition: "width ease-in-out, visibility 0.2s linear",
              overflowY: "auto",
              overflowX: "hidden",
              border: "none",
            },
            "& .MuiListItemIcon-root": {
              minWidth: "35px",
              color: "inherit",
              flexShrink: 0,
              display: "inline-flex",
            },
          }}
          open={isOpen}
          onClose={onToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div
            onClick={handleMenuClick}
            style={
              isOpen
                ? {
                    position: "absolute",
                    top: "25px",
                    right: "0px",
                    left: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            {isOpen && (
              <img
                src={logo}
                alt="Logo"
                style={{
                  top: "28px",
                  height: "34px",
                  marginRight: "20px",
                }}
              />
            )}
            {iconComponent}
          </div>
          <List sx={{ top: "65px", left: "5px" }}>
            {menuItems.map((item, index) => (
              <div key={index}>
                <Tooltip
                  title={isOpen ? "" : item.text}
                  placement="bottom"
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -15],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    onClick={() => item.expandIcon && toggleExpand(item.text)}
                  >
                    <SidebarItem
                      icon={item.icon}
                      text={item.text}
                      to={item.to}
                      isOpen={isOpen}
                      expandIcon={
                        item.expandIcon ? (
                          expandedItems[item.text] ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null
                      }
                    />
                  </div>
                </Tooltip>
                {expandedItems[item.text] && item.expandedContent && (
                  <div
                    className="expanded-content"
                    style={{
                      paddingLeft: "30px",
                      paddingTop: "3px",
                      paddingBottom: "3px",
                    }}
                  >
                    {item.expandedContent.map((subItem, subIndex) => (
                      <Tooltip
                        key={subIndex}
                        title={isOpen ? "" : subItem.text}
                        placement="bottom"
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        }}
                      >
                        <div>
                          <SidebarItem
                            icon={subItem.icon}
                            text={subItem.text}
                            to={subItem.to}
                            isOpen={isOpen}
                          />
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </List>
        </Drawer>
      )}
    </>
  );
};
export default Sidebar;
