import React, { useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import PheonixButton from "../Components/PheonixButton";
import { useTheme } from "@mui/material/styles";
import SupplierInfo from "./Supplierinfo";
import Creditterms from "./Creditterms";
import { TEXT_MESSAGES, ERROR_MESSAGES } from "../const";
import ModalFormData from "../interfaces/ModalFormDataProps";
import { EDIT_SUPPLIER } from '../graphql/query';
import axios from "axios";
import baseUrl from "../api";
import { print } from "graphql";
import { PheonixSnackbar } from "pheonixui";


interface AddSupplierProps {
  suppliers: ModalFormData;
  onSave: (updatedSupplier: ModalFormData) => void;
  onCancel: () => void;
  error?: string | null;
  onFileChange: (files: File[]) => void;
}

const steps = ["Supplier Personal Info", "Credit Terms"];
const EditSuppliers: React.FC<AddSupplierProps> = ({ suppliers, onSave, onCancel, onFileChange, }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [activeStep, setActiveStep] = useState(0);
  const [editedSupplier, setEditedSupplier] = useState<ModalFormData>(suppliers || {});
  const [error, setError] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [files, setFiles] = useState<File[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<"add" | "edit" | "delete" | "">("");
  const [snackbarTypecolor, setSnackbarTypecolor] = useState<"add" | "edit" | "delete" | "">("");
  const [alertmessage, setalertmessage] = useState("");

  const handleSnackbarOpen = (type: "add" | "edit" | "delete") => {
    setSnackbarType(type);
    setSnackbarTypecolor(type);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
      setSnackbarType("");
      setSnackbarTypecolor("");
      setalertmessage("");
    }, 3000);
  };

  const validateSupplierInfo = (data: Record<string, any>) => {
    const requiredFields = ["supplier_name", "phone_number"];
    let errors: Record<string, string> = {};
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field.replace(/_/g, " ")} is required`;
      }
    });
    return errors;
  };
  const validateCreditTerms = (data: Record<string, any>) => {
    const requiredFields = ["credit_term_date", "payment_period"];
    let errors: Record<string, string> = {};
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field.replace(/_/g, " ")} is required`;
      }
    });
    return errors;
  };
  const handleFileChange = (newFiles: File[]) => {
    const validFiles = newFiles.filter((file) => {
      if (file.type !== "application/pdf") {
        setError(`Invalid file: ${file.name}. Only PDF files are allowed.`);
        return false;
      }
      return true;
    });
    if (validFiles.length === 0) return;
    setFiles((prev) => {
      const updatedFiles = [...prev, ...validFiles];
      onFileChange(updatedFiles);
      return updatedFiles;
    });
  };

  const handleNext = async () => {
    let currentErrors: Record<string, string> = {};
    if (activeStep === 0) {
      currentErrors = validateSupplierInfo(editedSupplier);
    } else if (activeStep === 1) {
      currentErrors = validateCreditTerms(editedSupplier);
    }

    if (Object.keys(currentErrors).length > 0) {
      setFieldErrors(currentErrors);
      setError(Object.values(currentErrors).join(", "));
      return;
    }
    setError(null);
    setFieldErrors({});
    if (activeStep === steps.length - 1) {
      try {
        const { id, ...editedData } = editedSupplier;
        const variables = {
          id,
          ...editedData,
          documents: files.length > 0 ? files.map(() => null) : editedData.documents || null,
        };
        if (files.length > 0) {
          const formData = new FormData();

          formData.append(
            "operations",
            JSON.stringify({
              query: print(EDIT_SUPPLIER),
              variables,
            })
          );
          const map: Record<string, string[]> = {};
          files.forEach((_, index) => {
            map[`${index}`] = [`variables.documents.${index}`];
          });
          formData.append("map", JSON.stringify(map));
          files.forEach((file, index) => {
            formData.append(`${index}`, file);
          });
          const response = await axios.post(baseUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          const { editSupplier } = response.data.data;
          if (editSupplier?.statusCode === 200) {
            onSave({
              ...editedSupplier,
              documents: files.length > 0 ? files.map((file) => file.name) : editedSupplier.documents,
            });
            onCancel();
          } else {
            setError(editSupplier?.message || ERROR_MESSAGES.FAILED_UPDATE);
          }
        } else {
          const response = await axios.post(baseUrl, {
            query: print(EDIT_SUPPLIER),
            variables,
          });
          const { editSupplier } = response.data.data;
          if (editSupplier?.statusCode === 200) {
            onSave({
              ...editedSupplier,
              documents: editedSupplier.documents,
            });
            onCancel();
          } else {
            setError(editSupplier?.message || ERROR_MESSAGES.FAILED_UPDATE);
            setError(editSupplier?.message || ERROR_MESSAGES.UNKNOW);
            setalertmessage(editSupplier?.message);
            handleSnackbarOpen("delete");
            setSnackbarOpen(true);
          }
        }
      } catch (err: any) {
        console.error(ERROR_MESSAGES.NETWORK_ERROR, err.message || err);
        setError(ERROR_MESSAGES.FAILED_UPDATE);
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  const handleBack = () => setActiveStep((prev) => prev - 1);
  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        paddingX: "16px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        border: "1px solid",
        borderColor: "rgba(224,224,224,1)",
        height: isSmallScreen ? "90vh" : isMediumScreen ? "80vh" : "70vh",
        overflowY: "auto",
        boxSizing: "border-box",
      }}
    >
      <Box>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ fontFamily: "Lexend", display: "flex", justifyContent: "flex-start" }}
        >
          {steps[activeStep]}
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    color: "#8C8C8C",
                    "&.Mui-active": { color: "black" },
                    "&.Mui-completed": { color: "black" },
                  },
                }}
                sx={{
                  fontFamily: "Lexend",
                  "& .MuiStepLabel-label": { fontFamily: "Lexend" },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ flexGrow: 1 }}>
          {activeStep === 0 && (
            <SupplierInfo
              data={{
                supplier_name: editedSupplier.supplier_name,
                email: editedSupplier.email,
                country: editedSupplier.country,
                city: editedSupplier.city,
                shipping_address: editedSupplier.shipping_address,
                documents: editedSupplier.documents,
                phone_number: editedSupplier.phone_number,
                gst: editedSupplier.gst,
                state: editedSupplier.state,
                alter_phonenumber: editedSupplier.alter_phonenumber,
                billing_address: editedSupplier.billing_address
              }}
              onSave={(updatedData) =>
                setEditedSupplier((prev) => ({ ...prev, ...updatedData }))
              }
              error={error}
              fieldErrors={fieldErrors}
              onFileChange={handleFileChange}
              setFieldErrors={setFieldErrors}
            />
          )}
          {activeStep === 1 && (
            <Creditterms
              data={{
                credit_term_date: editedSupplier.credit_term_date,
                open_balance: editedSupplier.open_balance,
                grace_period: editedSupplier.grace_period,
                credit_limit: editedSupplier.credit_limit,
                payment_period: editedSupplier.payment_period,
                notes: editedSupplier.notes
              }}
              onSave={(updatedData) =>
                setEditedSupplier((prev) => ({ ...prev, ...updatedData }))
              }
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "auto",
          paddingX: "16px",
          paddingY: "10px",
          width: isSmallScreen ? "85%" : isMediumScreen ? "80%" : "90%",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {activeStep > 0 && (
            <PheonixButton
              label={TEXT_MESSAGES.BACK_BUTTON}
              width={isSmallScreen ? "79px" : "90px"}
              backgroundColor={"white"}
              borderColor={"black"}
              border="1px solid"
              color={"secondary"}
              variant="outlined"
              onClick={handleBack}
              sx={{
                color: "black",
                minWidth: "79px",
                minHeight: "33px",
                transform: "none",
                textTransform: "none",
                "&:hover": { borderColor: "black", backgroundColor: "white" },
              }}
            />
          )}
          <PheonixButton
            label={activeStep === 1 ? TEXT_MESSAGES.SAVE_BUTTON : TEXT_MESSAGES.NEXT_BUTTON}
            variant="outlined"
            width={isSmallScreen ? "79px" : "90px"}
            onClick={handleNext}
            sx={{
              backgroundColor: "black",
              minWidth: "79px",
              minHeight: "33px",
              color: "white",
              transform: "none",
              textTransform: "none",
              "&:hover": { backgroundColor: "black" },
            }}
          />
          <PheonixSnackbar
                      vertical="top"
                      horizontal="right"
                      open={snackbarOpen}
                      onClose={() => {
                        setSnackbarOpen(false);
                      }}
                      message={alertmessage}
                      severity="success"
                      backgroundColor={
                        snackbarOpen
                          ? snackbarType === "add"
                            ? "#2E7D32"
                            : snackbarType === "edit"
                              ? "#EDF7ED"
                              : "#D32F2F29"
                          : "transparent"
                      }
                      color={
                        snackbarOpen
                          ? snackbarTypecolor === "add"
                            ? "#FFFFFF"
                            : snackbarTypecolor === "edit"
                              ? "#1E4620"
                              : "#663C00"
                          : "transparent"
                      }
                      sx={{
                        fontFamily: "Lexend",
                        top: "170px",
                        fontWeight: 500,
                        fontSize: isSmallScreen ? "12px" : isMediumScreen ? "13px" : "14px",
                        mt: isSmallScreen ? 15 : isMediumScreen ? 12 : 12,
                        height: isSmallScreen ? "30px" : isMediumScreen ? "32px" : "45px",
                        paddingTop: isSmallScreen ? "2px" : "5px",
                        paddingBottom: isSmallScreen ? "4px" : "6px",
                        width: isSmallScreen
                          ? "90%" 
                          : isMediumScreen
                          ? "auto" 
                          : "auto", 
                        maxWidth: isSmallScreen ? "250px" : isMediumScreen ? "300px" : "350px", 
                        minWidth: "150px", 
                        whiteSpace: "normal", 
                        wordBreak: "break-word", 
                        overflowWrap: "break-word", 
                        textAlign: "center", 
                        marginTop: "50px",
                      }}
                    />
        </Box>
      </Box>
    </Box>
  );
};

export default EditSuppliers;