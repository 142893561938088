import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixTextField from "../Components/PheonixTextField";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from "@mui/icons-material/Close";
import { TEXT_MESSAGES, ERROR_MESSAGES } from "../const";

interface Option {
  value: string;
  label: string;
}

interface OptionsMap {
  [key: string]: Option[];
}

interface SupplierinfoProps {
  data: Record<string, any>;
  error?: string | null;
  readOnly?: boolean;
  onSave: (data: Record<string, any>) => void;
  fieldErrors: Record<string, string>;
  setFieldErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  onFileChange?: (files: File[]) => void;
}

const SupplierInfo: React.FC<SupplierinfoProps> = ({
  onSave,
  readOnly = false,
  error,
  data,
  fieldErrors,
  setFieldErrors,
  onFileChange,
}) => {
  const [formData, setFormData] = useState<Record<string, any>>(data || {});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [files, setFiles] = useState<File[]>([]);
  const [base64Files, setBase64Files] = useState<string[]>(data.documents || []);


  useEffect(() => {
    setFormData(data || {});
    setSelectedCountry(data?.country || null);
    setSelectedState(data?.state || null);
  }, [data]);

  const commonStyles = {
    textField: {
      width: "100%",
      maxWidth: "348px",
      height: "48px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root": {
        color: "gray",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "gray",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "10px",
        fontFamily: "Lexend",
        margin: 0,
        padding: 0,
      },
    },
    autoComplete: {
      width: "100%",
      maxWidth: "348px",
    },
  };

  useEffect(() => {
    setFormData(data);
    setBase64Files(data.documents || []);
    setFiles([]);
  }, [data]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleUpload(e.target.files);
      e.target.value = "";
    }
  };

  const handleUpload = (uploadedFiles: FileList) => {
    const newFiles = Array.from(uploadedFiles).filter((file) => file.type === "application/pdf");
    if (newFiles.length === 0) return;

    setFiles((prev) => {
      const updatedFiles = [...prev, ...newFiles];
      if (onFileChange) {
        onFileChange(updatedFiles);
      }
      return updatedFiles;
    });

    setFormData((prev) => {
      const updatedData = { ...prev, documents: [...base64Files, ...newFiles] };
      onSave(updatedData);
      return updatedData;
    });
  };


  const removeFile = (index: number) => {
    if (index < base64Files.length) {

      const updatedBase64Files = base64Files.filter((_, i) => i !== index);
      setBase64Files(updatedBase64Files);
      const updatedData = { ...formData, documents: [...updatedBase64Files, ...files] };
      setFormData(updatedData);
      onSave(updatedData);
      if (onFileChange) {
        onFileChange(files);
      }
    } else {
      const newIndex = index - base64Files.length;
      const updatedFiles = files.filter((_, i) => i !== newIndex);
      setFiles(updatedFiles);
      const updatedData = { ...formData, documents: [...base64Files, ...updatedFiles] };
      setFormData(updatedData);
      onSave(updatedData);
      if (onFileChange) {
        onFileChange(updatedFiles);
      }
    }
  };
  const downloadFile = (base64String: string, fileName: string) => {
    try {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName || TEXT_MESSAGES.DOWNLOAD_PDF;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(ERROR_MESSAGES.DOWNLOAD_FILE, err);
    }
  };
  const allFiles = [...base64Files, ...files];

  const handleInputChange = (field: string, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    onSave({ ...formData, [field]: value });

    if (touched[field]) {
      const error = validateField(field, value, { ...formData, [field]: value });
      setFieldErrors((prev) => ({ ...prev, [field]: error }));
    }
  };

  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const error = validateField(field, formData[field], formData);
    setFieldErrors((prev) => ({ ...prev, [field]: error }));
  };

  const validatePhoneNumber = (input: string): string | null => {
    const cleanedInput = input.replace(/[^\d+\-().\sx]/g, "");
    const digitsOnly = cleanedInput.replace(/\D/g, "");

    if (digitsOnly.length < 7 || digitsOnly.length > 15) {
        return ERROR_MESSAGES.NUMBER_LENGTH;
    }

    const validPattern = /^(\+?[1-9]\d{0,2}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;

    if (!validPattern.test(cleanedInput)) {
        return ERROR_MESSAGES.NUMB_INVALID;
    }

    if (/[A-Za-z]/.test(cleanedInput.replace(/\s?x\s?\d+$/, ""))) {
        return ERROR_MESSAGES.NUMB_LETTERS;
    }

    if (/[^0-9 ()+x-]/.test(cleanedInput)) {
        return ERROR_MESSAGES.NUMB_SPCL;
    }

    return null; 
};


  const validateField = (field: string, value: any, formData: Record<string, any>): string => {
    let error = "";
    const requiredFields = [
      "supplier_name",
      "email",
      "country",
      "city",
      "shipping_address",
      "phone_number",
      "gst",
      "state",
      "billing_address",
    ];

    if (field === "supplier_name" && value) {
      const nameRegex = /^[A-Za-z\s]+$/;
      if (!nameRegex.test(value)) {
        error = ERROR_MESSAGES.NAME_SUPPLIER;
      }
    }

    if (requiredFields.includes(field)) {
      if (!value || (typeof value === "string" && value.trim() === "")) {
        error = `${field.replace("_", " ")} is required`;
      }
    }

    if (field === "email" && value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = ERROR_MESSAGES.INVALID_EMAIL;
      }
    }

    if (field === "phone_number" && value) {
      const phoneRegex = /^\d{10}$/;
      if (value === formData.alter_phonenumber) {
        error = ERROR_MESSAGES.SUPPLIER_ALTERNO;
      }
      else if (validatePhoneNumber(value)) {
        error = ERROR_MESSAGES.SUPPLIER_PHONE;
      }
    }

    if (field === "alter_phonenumber" && value) {
      const phoneRegex = /^\d{10}$/;
      if (value === formData.phone_number) {
        error = ERROR_MESSAGES.SUPPLIER_NO;
      }
      else if (validatePhoneNumber(value)) {
        error = ERROR_MESSAGES.SUPPLIER_ALTERNO;
      }
    }


    if (field === "gst" && value) {
      if (!value.trim()) {
        error = ERROR_MESSAGES.GST_NO_IS_REQUIRED;
      } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
        error = ERROR_MESSAGES.GST_NO_SHOULD;
      } else if (value.length !== 15) {
        error = ERROR_MESSAGES.GST_NO_SHOULD_1;
      } else if (!/[A-Za-z]/.test(value) || !/\d/.test(value)) {
        error = ERROR_MESSAGES.GST_BOTH;
      }
    }

    return error;
  };
  const countryCodes = [
    { label: "India (+91)", value: "+91" },
    { label: "USA (+1)", value: "+1" },
    { label: "Europe (+44)", value: "+44" },
  ];
  const countryOptions: Option[] = [
    { value: "India", label: "India" },
    { value: "U.S", label: "U.S" },
    { value: "Europe", label: "Europe" },
  ];

  const stateOptions: OptionsMap = {
    India: [
      { value: "Tamil Nadu", label: "Tamil Nadu" },
      { value: "Kerala", label: "Kerala" },
      { value: "Karnataka", label: "Karnataka" },
    ],
    "U.S": [
      { value: "California", label: "California" },
      { value: "Texas", label: "Texas" },
      { value: "New York", label: "New York" },
    ],
    Europe: [
      { value: "London", label: "London" },
      { value: "Paris", label: "Paris" },
      { value: "Berlin", label: "Berlin" },
      { value: "Ireland", label: "Ireland" },
    ],
  };

  const cityOptions: OptionsMap = {
    "Tamil Nadu": [
      { value: "Chennai", label: "Chennai" },
      { value: "Coimbatore", label: "Coimbatore" },
      { value: "Madurai", label: "Madurai" },
    ],
    Kerala: [
      { value: "Kochi", label: "Kochi" },
      { value: "Trivandrum", label: "Trivandrum" },
      { value: "Kozhikode", label: "Kozhikode" },
    ],
    Karnataka: [
      { value: "Bangalore", label: "Bangalore" },
      { value: "Mysore", label: "Mysore" },
      { value: "Mangalore", label: "Mangalore" },
    ],
    California: [
      { value: "Los Angeles", label: "Los Angeles" },
      { value: "San Francisco", label: "San Francisco" },
      { value: "San Diego", label: "San Diego" },
    ],
    Texas: [
      { value: "Houston", label: "Houston" },
      { value: "Austin", label: "Austin" },
      { value: "Dallas", label: "Dallas" },
    ],
    "New York": [
      { value: "New York City", label: "New York City" },
      { value: "Buffalo", label: "Buffalo" },
      { value: "Rochester", label: "Rochester" },
    ],
    London: [
      { value: "Westminster", label: "Westminster" },
      { value: "Camden", label: "Camden" },
      { value: "Chelsea", label: "Chelsea" },
    ],
    Paris: [
      { value: "Montmartre", label: "Montmartre" },
      { value: "Le Marais", label: "Le Marais" },
      { value: "Latin Quarter", label: "Latin Quarter" },
    ],
    Ireland: [
      { value: "Dublin", label: "Dublin" },
      { value: "Cork", label: "Cork" },
      { value: "Limerick", label: "Limerick" },
    ],
  };

  const handleCountryChange = (_: React.SyntheticEvent, newValue: Option | null) => {
    setSelectedCountry(newValue?.value || null);
    setSelectedState(null);
    const newValueStr = newValue?.value || "";
    handleInputChange("country", newValueStr);
    const error = validateField("country", newValueStr, { ...formData, country: newValueStr });
    setFieldErrors((prev) => ({ ...prev, country: error }));
  };
  const handleStateChange = (_: React.SyntheticEvent, newValue: Option | null) => {
    setSelectedState(newValue?.value || null);
    const newValueStr = newValue?.value || "";
    handleInputChange("state", newValueStr);
    const error = validateField("state", newValueStr, { ...formData, state: newValueStr });
    setFieldErrors((prev) => ({ ...prev, state: error }));
  };

  const getAvailableStates = (): Option[] => {
    return selectedCountry ? stateOptions[selectedCountry] || [] : [];
  };

  const getAvailableCities = (): Option[] => {
    return selectedState ? cityOptions[selectedState] || [] : [];
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : isMediumScreen ? "column" : "row",
        gap: isSmallScreen ? "16px" : "24px",
        padding: isSmallScreen ? "16px" : "30px",
        height: "auto",
        overflow: "auto",
      }}>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "17px" }}>
        <PheonixTextField
          type="text"
          label="Supplier Name"
          variant="standard"
          required
          value={formData.supplier_name || ""}
          onChange={(e) => handleInputChange("supplier_name", e.target.value)}
          onBlur={() => handleBlur("supplier_name")}
          helperText={fieldErrors.supplier_name}
          error={!!fieldErrors.supplier_name}
          disabled={readOnly}
          sx={commonStyles.textField}
        />
        <PheonixTextField
          label="Email"
          type="text"
          variant="standard"
          required
          value={formData.email || ""}
          onChange={(e) => handleInputChange("email", e.target.value)}
          onBlur={() => handleBlur("email")}
          helperText={fieldErrors.email}
          error={!!fieldErrors.email}
          disabled={readOnly}
          sx={commonStyles.textField}
        />
        <Autocomplete<Option>
          options={countryOptions}
          getOptionLabel={(option) => option.label}
          onChange={handleCountryChange}
          value={countryOptions.find((opt) => opt.value === formData.country) || null}
          disabled={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              required
              variant="standard"
              onBlur={() => handleBlur("country")}
              helperText={fieldErrors.country}
              error={!!fieldErrors.country}
              sx={commonStyles.textField}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ fontFamily: "Lexend" }}>
              {option.label}
            </li>
          )}
          sx={commonStyles.autoComplete}
        />
        <Autocomplete<Option>
          options={getAvailableStates()}
          getOptionLabel={(option) => option.label}
          onChange={handleStateChange}
          value={stateOptions[selectedCountry || ""]?.find((opt) => opt.value === formData.state) || null}
          disabled={!selectedCountry || readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              required
              variant="standard"
              onBlur={() => handleBlur("state")}
              helperText={fieldErrors.state}
              error={!!fieldErrors.state}
              sx={commonStyles.textField}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ fontFamily: "Lexend" }}>
              {option.label}
            </li>
          )}
          sx={commonStyles.autoComplete}
        />

        <Autocomplete<Option>
          options={getAvailableCities()}
          getOptionLabel={(option) => option.label}
          onChange={(_, newValue) => handleInputChange("city", newValue?.value || "")}
          onBlur={() => handleBlur("city")}
          value={cityOptions[selectedState || ""]?.find((opt) => opt.value === formData.city) || null}
          disabled={!selectedState || readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              label="City"
              required
              variant="standard"
              helperText={fieldErrors.city}
              error={!!fieldErrors.city}
              sx={commonStyles.textField}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ fontFamily: "Lexend" }}>
              {option.label}
            </li>
          )}
          sx={commonStyles.autoComplete}
        />
        <PheonixTextField
          label="Reference Attachments"
          type="text"
          disabled={readOnly}
          variant="standard"
          value={
            allFiles.length > 0
              ? allFiles
                .map((item, index) =>
                  item instanceof File ? item.name : `file_${index + 1}.pdf`
                )
                .join(", ")
              : ""
          }

          InputProps={{
            endAdornment: !readOnly && (
              <AttachFileIcon
                sx={{ cursor: "pointer", color: "gray", "&:hover": { color: "black" } }}
                onClick={() => document.getElementById("file-upload")?.click()}
              />
            ),
          }}
          sx={commonStyles.textField}
        />

        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
          accept=".pdf"
          multiple
          onChange={handleFileChange}
          disabled={readOnly}
        />

        {allFiles.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1 }}>
            {allFiles.map((item, index) => {
              const fileName =
                item instanceof File ? item.name : `filename_${index + 1}.pdf`;
              const isBase64File = index < base64Files.length;
              return (
                <Chip
                  key={index}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                      <Typography sx={{ color: isBase64File ? "black" : "black" }}>
                        {fileName}
                      </Typography>
                      {isBase64File && (
                        <FileDownloadOutlinedIcon
                          sx={{ fontSize: "16px", color: "black" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadFile(base64Files[index], fileName);
                          }}
                        />
                      )}
                    </Box>
                  }
                  onDelete={!readOnly ? () => removeFile(index) : undefined}
                  deleteIcon={
                    !readOnly ? (
                      <CloseIcon sx={{ fontSize: "5px", color: "gray" }} />
                    ) : undefined
                  }
                  sx={{
                    backgroundColor: "#e0e0e0",
                    "&:hover": { backgroundColor: "#d0d0d0" },
                  }}
                />
              );
            })}
          </Box>
        )}
      </Box>

      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "17px" }}>
        <PheonixTextField
          type="text"
          label="Phone Number"
          variant="standard"
          required
          value={formData.phone_number || ""}
          onChange={(e) => handleInputChange("phone_number", e.target.value)}
          onBlur={() => handleBlur("phone_number")}
          helperText={fieldErrors.phone_number}
          error={!!fieldErrors.phone_number}
          disabled={readOnly}
          sx={commonStyles.textField}
        />
        <PheonixTextField
          type="text"
          label="GSTIN"
          variant="standard"
          required
          value={formData.gst || ""}
          onChange={(e) => handleInputChange("gst", e.target.value)}
          onBlur={() => handleBlur("gst")}
          helperText={fieldErrors.gst}
          error={!!fieldErrors.gst}
          disabled={readOnly}
          sx={commonStyles.textField}
        />
        <PheonixTextField
          type="text"
          label="Alternative Phone Number"
          variant="standard"
          value={formData.alter_phonenumber || ""}
          onChange={(e) => handleInputChange("alter_phonenumber", e.target.value)}
          onBlur={() => handleBlur("alter_phonenumber")}
          helperText={fieldErrors.alter_phonenumber}
          error={!!fieldErrors.alter_phonenumber}
          disabled={readOnly}
          sx={commonStyles.textField}
        />
        <PheonixTextField
          id="outlined-multiline-flexible"
          type="textarea"
          label="Shipping Address"
          variant="standard"
          multiline={true}
          maxRows={2}
          value={formData.shipping_address || ""}
          onChange={(e) => handleInputChange("shipping_address", e.target.value)}
          onBlur={() => handleBlur("shipping_address")}
          helperText={fieldErrors.shipping_address}
          error={!!fieldErrors.shipping_address}
          required
          disabled={readOnly}
          sx={commonStyles.textField}
        />

        <PheonixTextField
        id="outlined-multiline-flexible"
          type="textarea"
          label="Billing Address"
          variant="standard"
          multiline={true}
          maxRows={2}
          required
          value={formData.billing_address || ""}
          onChange={(e) => handleInputChange("billing_address", e.target.value)}
          onBlur={() => handleBlur("billing_address")}
          helperText={fieldErrors.billing_address}
          error={!!fieldErrors.billing_address}
          disabled={readOnly}
          sx={{ ...commonStyles.textField, marginTop: 1 }}
        />
      </Box>
    </Box>
  );
};

export default SupplierInfo;