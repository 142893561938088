import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixTextField from "../Components/PheonixTextField";
import { ERROR_MESSAGES } from '../const';

interface CredittermscustomerProps {
  error?: string | null;
  readOnly?: boolean;
  data: Record<string, any>;
  onSave: (data: Record<string, any>) => void;
  fieldErrors: Record<string, string>;
  setFieldErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}
const Credittermscustomer: React.FC<CredittermscustomerProps> = ({
  data,
  onSave,
  fieldErrors,
  setFieldErrors,
  readOnly = false,
  error
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const formatDate = (date: string | Date | undefined): string => {
    if (!date) return ""; 
    const d = new Date(date);
    return isNaN(d.getTime()) ? "" : d.toISOString().split("T")[0];
  };
  const [formData, setFormData] = useState<Record<string, any>>({
    ...data,
    credit_term_date: formatDate(data?.credit_term_date),
  });
  useEffect(() => {
    setFormData({
      ...data,
      credit_term_date: formatDate(data?.credit_term_date),
    });
  }, [data]);
 const commonStyles = {
    textField: {
      width: "100%",
      maxWidth: "348px",
      height: "48px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root": {
        color: "gray",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "gray",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "10px",
        fontFamily: "Lexend",
        margin: 0,
        padding: 0,
      },
    },
    autoComplete: {
      width: "100%",
      maxWidth: "348px",
    },
  };
 const validateField = (field: string, value: any): string => {
     let error = "";
     if (field === "credit_term_date" && !value) {
       error = ERROR_MESSAGES.CREDIT_TERMREQ; 
     }
     if (field === "payment_period" && !value) {
       error = ERROR_MESSAGES.PAYMENT_PERIODREQ;
     }
     return error;
   };
 const handleInputChange = (field: string, value: any) => {
    let parsedValue: any = value;
    if (["open_balance", "grace_period", "credit_limit", "payment_period"].includes(field)) {
      parsedValue = value === "" ? "" : Number(value);
    }
    setFormData((prev) => ({ ...prev, [field]: parsedValue }));
    onSave({ ...formData, [field]: parsedValue });
    if (touched[field]) {
      const error = validateField(field, parsedValue);
      setFieldErrors((prev) => ({ ...prev, [field]: error }));
    }
  };
  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const error = validateField(field, formData[field]);
    setFieldErrors((prev) => ({ ...prev, [field]: error }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : isMediumScreen ? "column" : "row",
        gap: isSmallScreen ? "16px" : "24px",
        padding: isSmallScreen ? "16px" : "32px",
        height: "auto",
        overflow: "auto",
      }}
    >
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: { xs: "12px", sm: "16px" } }}>
        <PheonixTextField
          type="date"
          label="Credit Term Date"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.credit_term_date || ""}
          onChange={(e) => handleInputChange("credit_term_date", e.target.value)}
          onBlur={() => handleBlur("credit_term_date")}
          helperText={fieldErrors.credit_term_date}
          error={!!fieldErrors.credit_term_date}
          InputProps={{
            inputProps: {
              placeholder: formData.credit_term_date ? " " : " credit_term_date",
              min: new Date().toISOString().split("T")[0],
            },
      }}
           sx={{
                     width: isSmallScreen ? "100%" : "348px",
                     height: "48px",
                     "& .MuiInputBase-root input::-webkit-datetime-edit": {
                       opacity: formData.credit_term_date ? 1 : 0,
                     },
                     "& .MuiInputBase-root": {
                       backgroundColor: "transparent",
                     },
                     "& .MuiInputLabel-root": {
                       color: "gray",
                       fontFamily: "Lexend",
                     },
                     "& .MuiInputLabel-root.Mui-focused": {
                       color: "black",
                       backgroundColor: "#FFFFFF",
                       fontFamily: "Lexend",
                     },
                     "& .MuiInput-underline:before": {
                       borderBottomColor: "gray",
                     },
                     "& .MuiInput-underline:after": {
                       borderBottomColor: "gray",
                     },
                     "& .MuiFormLabel-asterisk": {
                       color: "red",
                     },
                     "& .MuiFormHelperText-root": {
                       fontSize: "10px",
                       fontFamily: "Lexend",
                       margin: 0,
                       Padding: 0,
                     },
                   }}
        />
        <PheonixTextField
          type="text"
          label="Opening Balance"
          disabled={readOnly}
          variant="standard"
          value={formData.open_balance || ""}
          onChange={(e) => handleInputChange("open_balance", e.target.value)}
          onBlur={() => handleBlur("open_balance")}
          helperText={fieldErrors.open_balance}
          error={!!fieldErrors.open_balance}
          sx={commonStyles.textField}
        />
        <PheonixTextField
          type="text"
          label="Grace Period of Days"
          disabled={readOnly}
          variant="standard"
          value={formData.grace_period || ""}
          onChange={(e) => handleInputChange("grace_period", e.target.value)}
          onBlur={() => handleBlur("grace_period")}
          helperText={fieldErrors.grace_period}
          error={!!fieldErrors.grace_period}
          sx={commonStyles.textField}
        />
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "16px" }}>
        <PheonixTextField
          type="text"
          label="Credit Limit"
          disabled={readOnly}
          variant="standard"
          value={formData.credit_limit || ""}
          onChange={(e) => handleInputChange("credit_limit", e.target.value)}
          onBlur={() => handleBlur("credit_limit")}
          helperText={fieldErrors.credit_limit}
          error={!!fieldErrors.credit_limit}
          sx={commonStyles.textField}
        />
        <PheonixTextField
          type="text"
          label="Payment Period of Days"
          disabled={readOnly}
          variant="standard"
          required
          value={formData.payment_period || ""}
          onChange={(e) => handleInputChange("payment_period", e.target.value)}
          onBlur={() => handleBlur("payment_period")}
          helperText={fieldErrors.payment_period}
          error={!!fieldErrors.payment_period}
          sx={commonStyles.textField}
        />
        <PheonixTextField
          type="text"
          label="Notes"
          disabled={readOnly}
          variant="standard"
          value={formData.notes || ""}
          onChange={(e) => handleInputChange("notes", e.target.value)}
          onBlur={() => handleBlur("notes")}
          helperText={fieldErrors.notes}
          error={!!fieldErrors.notes}
          sx={commonStyles.textField}
        />
      </Box>
    </Box>
  );
};

export default Credittermscustomer;