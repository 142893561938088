import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
} from "@mui/material";
import PheonixButton from "../Components/PheonixButton";
import { useQuery, useMutation } from "@apollo/client";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { GridColDef } from "@mui/x-data-grid";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import themestyle from "../theme";
import EditIcon1 from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PheonixPaper from "../Components/PheonixPaper";
import ModalFormData from "../interfaces/ModalFormDataProps";
import axios from "axios";
import baseUrl from "../api";
import ProductEdit from "./EditProduct";
import ProductView from "./ProductView";
import { GET_PRODUCT_QUERY, DELETE_PRODUCT } from "../graphql/query";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";
import Addproduct from "./Addproduct";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import CustomToolbar from "../Components/PheonixDataGrid";
import product from "../assets/product.png";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import { PheonixBox, PheonixSnackbar } from "pheonixui";
import ImportBulkFile from "./Importfile";
import EditConfirmationDialog from "./Editmodel";
import DeleteConfirmationDialog from "./Deletemodel";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PheonixPopup from "../Components/PheonixPopup";
import { useLocation } from 'react-router-dom';


const Product: React.FC = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [dataLoading, setdataLoading] = useState(false);
  const [tableData, setTableData] = useState<ModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showProduct, setShowProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<"add" | "edit" | "delete" | "">("");
  const [snackbarTypecolor, setSnackbarTypecolor] = useState<"add" | "edit" | "delete" | "">("");
  const [alertmessage, setalertmessage] = useState("");
  const [stockAlertBgColor, setStockAlertBgColor] = useState<string>("");
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeStep, setActiveStep] = useState(0);
  const [searchInput, setSearchInput] = useState<string>("");
  const location = useLocation();
  const [currentView, setCurrentView] = useState<string>(location.state?.currentView || 'list' || 'addproduct' || 'view' || "edit");
  const [breadcrumbText, setBreadcrumbText] = useState<string>(location.state?.breadcrumbText || '');
  const [popup, setPopup] = useState(false)
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (newFiles: File[]) => {
    setFiles(newFiles);
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.currentView) {
        setCurrentView(location.state.currentView);
        setShowProduct(true);
      }
      if (location.state.breadcrumbText) {
        setBreadcrumbText(location.state.breadcrumbText);
      }
    }
  }, [location.state]);

  const handleSnackbarOpen = (type: "add" | "edit" | "delete") => {
    setSnackbarType(type);
    setSnackbarTypecolor(type);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
      setSnackbarType("");
      setSnackbarTypecolor("");
    }, 3000);
  };
  const handleOpenImportModal = () => {
    setImportModalOpen(true);
  };

  const handleCloseImportModal = () => {
    setImportModalOpen(false);
    productRefetch();
  };

  const handleOpenDialog = (product: ModalFormData) => {
    setSelectedProduct(product);
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedProduct(null);
  };
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: productRefetch,
  } = useQuery(GET_PRODUCT_QUERY, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery,
    },

    onCompleted: (data) => {
      const { totalPages, totalCount, product } = data.getProductsperPage;

      const startIndex = paginationModel.page * paginationModel.pageSize + 1;

      const productsWithSerialNo = product.map(
        (product: ModalFormData, index: number) => {
          let stockAlertBgColor = "";
          const today = new Date().toISOString().split("T")[0];
          const productExpiryDate = new Date(product.expirydate)
            .toISOString()
            .split("T")[0];

            if (productExpiryDate === today) {
              stockAlertBgColor = "#FEBEBE"; 
            } else if (product.quantity === 0) {
              stockAlertBgColor = "#D2D2D2"; 
            } 
            else if (product.quantity <= product.stock_alert) {
              stockAlertBgColor = "#FFBB3DC2"; 
            } 
          return {
            ...product,
            id: product.id || `${paginationModel.page}-${startIndex + index}`,
            serial_no: startIndex + index + 1,
            product_name: product.product_name, 
            openStock: stockAlertBgColor,
          };
        }
      );
      setTableData(productsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    },
  });
  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, product } = customerData.getProductsperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const today = new Date().toISOString().split("T")[0];
      const productsWithSerialNo = product.map(
        (product: ModalFormData, index: number) => {
          let stockAlertBgColor = "";
          const productExpiryDate = new Date(product.expirydate).toISOString().split("T")[0];

          if (productExpiryDate === today) {
            stockAlertBgColor = "#FEBEBE"; 
          } else if (product.quantity === 0) {
            stockAlertBgColor = "#D2D2D2"; 
          }
           else if (product.quantity <= product.stock_alert) {
            stockAlertBgColor = "#FFBB3DC2"; 
          }

          return {
            ...product,
            id: product.id || `${paginationModel.page}-${startIndex + index}`,
            serial_no: startIndex + index + 1,
            product_name: product.product_name, 
            openStock: stockAlertBgColor
          };
        }
      );
      setTableData(productsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      productRefetch({ variables: { searchQuery: {} } });
    } else {
      productRefetch();
    }
  };

  const handlePaginationChange = (newModel: {
    page: number;
    pageSize: number;
  }) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: newModel.page,
      pageSize: newModel.pageSize,
    }));
  };
  useEffect(() => {
    setActiveStep(0);
    productRefetch({
      variables: {
        page: paginationModel.page,
        perPage: paginationModel.pageSize,
        searchQuery: searchQuery.trim() === "" ? undefined : searchQuery,
      },
    });
  }, [searchQuery, paginationModel.page, paginationModel.pageSize]);

  const handleAddProduct = () => {
    setCurrentView("addproduct");
    setBreadcrumbText("Add Product");
    setShowProduct(true);
  };

  const handleSave = (newProduct: ModalFormData) => {
    const newProductWithId = {
      ...newProduct,
      id: newProduct.id || `temp-id-${Math.random()}`,
    };
    setTableData((prev) => [...prev, newProductWithId]);
    setShowProduct(false);
    setCurrentView("list");
    setBreadcrumbText("Product Listing");
    productRefetch();
    
    const successMessage = TEXT_MESSAGES.ADDING_PRODUCT.replace(
      "{code}",
      newProduct.product_name || "New Product"
    );
    setalertmessage(successMessage);
    handleSnackbarOpen("add");
  };

  const handleView = (selectedRow: ModalFormData) => {
    setSelectedProduct(selectedRow);
    setBreadcrumbText("View Product");
    setCurrentView("view");
  };

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };
  const handleBack = () => {
    setCurrentView(() => "list");
    setBreadcrumbText("Product Listing");
    setShowProduct(false);
    productRefetch();
  };

  const handleEditConfirmation = (selectedRow: ModalFormData) => {
    setIsEditDialogOpen(false);
    setSelectedProduct(selectedRow);
    setBreadcrumbText("Edit Product");
    setCurrentView("edit");
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setActiveStep(0);
    setIsEditing(false);
    setCurrentView("list");
    setBreadcrumbText("Product Listing");
  };

  const handleSaveEdit = (updatedProduct: ModalFormData) => {
    const updatedTableData = tableData.map((product) =>
      product.id === updatedProduct.id ? { ...updatedProduct, stock_reference: files.length > 0 ? files.map(f => f.name) : updatedProduct.stock_reference } : product
    );
    setTableData(updatedTableData);
    setBreadcrumbText("Product Listing");
    setCurrentView("list");
    const editedProductName =
      selectedProduct?.product_name || "Unknown";
    setSelectedProduct(null);
    productRefetch();
    const successMessage = TEXT_MESSAGES.EDITED_PRODUCT.replace(
      "{code}",
      editedProductName
    );
    setalertmessage(successMessage);
    handleSnackbarOpen("edit");
  };
  const handleDelete = async (product: ModalFormData): Promise<void> => {
    try {
      const response = await axios.post(baseUrl, {
        query: DELETE_PRODUCT.loc?.source.body,
        variables: { id: product.id },
      });

      const { statusCode, message } = response.data.data.deleteProduct;
      if (statusCode === 200) {
        setTableData((prev) => prev.filter((p) => p.id !== product.id));
        const editedProductName =
          selectedProduct?.product_name || "Unknown";
        const successMessage = TEXT_MESSAGES.DELETE_PRODUCT.replace(
          "{code}",
          editedProductName
        );
        setalertmessage(successMessage);
        handleSnackbarOpen("delete");
        productRefetch();
      } else {
        alert(message || "Failed to delete product.");
      }
    } catch (error: any) {
      alert(`Error: ${error.message}`);
    }
  };
  const handleBreadcrumbClick = (breadcrumbText: string) => {
    if (breadcrumbText === "product" && (currentView !== "list")) {
      setPopup(true)
    }
  };

  const handlePopupclose = () => {
    setActiveStep(0);
    setCurrentView("list");
    setShowProduct(false)
    setBreadcrumbText("Product listing")
    setPopup(false)
    setIsEditing(false)

  }
  const columns: GridColDef[] = [
    { field: "product_name", headerName: "Product Name", width: 200 },
    { field: "hsn_code", headerName: "HSN Code", flex: 1, minWidth: 60 },

    {
      field: "quantity",
      headerName: "Stock",
      flex: 1,
      minWidth: 60,
      renderCell: (params) => {
        const stockAlertBgColor = params.row.openStock;
        const stockValue = params.value || 0;
        const stockUnit = params.row.unit || "";
        return (
          <span
            style={{
              backgroundColor: stockAlertBgColor,
              display: "inline-flex",
              padding: "4px 8px",
              borderRadius: "6px",
              height: "fit-content",
              lineHeight: "1.2",
              whiteSpace: "nowrap",
            }}
          >
            {`${stockValue} ${stockUnit}`}
          </span>
        );
      },
    },

    { field: "sales_price", headerName: "Sales Price", flex: 1, minWidth: 60 },
    {
      field: "purchase_price",
      headerName: "Purchase Price",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-8px",
          }}
        >
          <IconButton onClick={() => handleView(params.row)}>
            <RemoveRedEyeSharpIcon
              style={{
                width: "20px",
                height: "20px",
                color: "black",
                marginTop: "7px",
              }}
            />
          </IconButton>
          <IconButton onClick={() => handleOpenDialog(params.row)}>
            <DeleteIcon
              style={{
                width: "20px",
                height: "20px",
                color: "black",
                marginTop: "7px",
              }}
            />
          </IconButton>
          <DeleteConfirmationDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            productName={selectedProduct?.product_name || ""}
            onConfirm={handleDelete}
            selectedProduct={selectedProduct}
            entityName={TEXT_MESSAGES.INFORMATION}
          />
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: isMobileSmall ? "15px 25px" : "15px 30px",
            marginLeft: "-12px",
            fontFamily: "Lexend",
          }}
        >
          <Typography
            style={{
              fontFamily: "Lexend",
              color: themestyle.colors.black,
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
              marginBottom: "8px",
            }}>
            {TEXT_MESSAGES.PRODUCT}
          </Typography>
          <PheonixBreadcrumbs breadcrumbText={breadcrumbText} onBreadcrumbClick={handleBreadcrumbClick} />
        </div>
        {currentView === "view" && selectedProduct && (
          <PheonixButton
            width={isSmallScreen ? "43px" : "90px"}
            label={
              isSmallScreen ? (
                <EditIcon1 style={{ height: "24px", width: "24px" }} />
              ) : (
                TEXT_MESSAGES.EDIT_BUTTON
              )
            }
            startIcon={
              !isSmallScreen ? (
                <EditIcon1 style={{ height: "24px", width: "24px" }} />
              ) : null
            }
            sx={{
              background: themestyle.colors.Primary2,
              color: themestyle.colors.white,
              minWidth: isSmallScreen ? "30px" : "90px",
              marginRight: "20px",
              transform: "none",
              textTransform: "none",
              marginTop: "20px",
            }}
            onClick={handleEditClick}
          />
        )}
        <EditConfirmationDialog
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          productName={selectedProduct?.product_name || ""}
          onConfirm={handleEditConfirmation}
          selectedProduct={selectedProduct}
          entityName={TEXT_MESSAGES.INFORMATION}
        />
        {currentView === "list" && tableData.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}>
            <div
              style={{
                width: isSmallScreen ? "85px" : isMediumScreen ? "340px" : "401px",
                height: isSmallScreen ? "32px" : "43px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: "nowrap",
                gap: isSmallScreen ? "13px" : isMediumScreen ? "15px" : "32px",
                padding: isMediumScreen ? "16px" : "0",
                marginTop: "25px",
                marginRight: "25px",
              }}
            >
              <PheonixButton
                label={
                  isSmallScreen ? (
                    <ListAltIcon style={{ width: "24px", height: "24px" }} />
                  ) : (
                    TEXT_MESSAGES.ADD_PRODUCT
                  )
                }
                style={{
                  background: themestyle.colors.Primary2,
                  color: themestyle.colors.white,
                  width: isSmallScreen ? "auto" : "150px",
                  minWidth: isSmallScreen ? "40px" : "150px",
                  height: "43px",
                  fontWeight: 500,
                  fontSize: "16px",
                  transform: "none",
                  textTransform: "none",
                  fontFamily: "Lexend",
                  padding: isSmallScreen ? "5px" : "10px",
                }}
                onClick={handleAddProduct}
              ></PheonixButton>
              <PheonixButton
                label={
                  isSmallScreen ? (
                    <FilePresentIcon
                      style={{ height: "24px", width: "24px" }}
                    />
                  ) : (
                    TEXT_MESSAGES.IMPORT_PRODUCT
                  )
                }
                startIcon={
                  !isSmallScreen ? (
                    <FilePresentIcon
                      style={{ height: "24px", width: "24px" }}
                    />
                  ) : null
                }
                style={{
                  background: themestyle.colors.Primary2,
                  color: themestyle.colors.white,
                  width: isSmallScreen ? "30px" : "219px",
                  minWidth: isSmallScreen ? "40px" : "240px",
                  height: "43px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 500,
                  fontFamily: "Lexend",
                  fontSize: "16px",
                  textTransform: "none",
                  marginRight: "15px",
                  borderRadius: "4px",
                  flexShrink: 0,
                }}
                onClick={handleOpenImportModal}
              >
                {" "}
              </PheonixButton>
              {isImportModalOpen && (
                <ImportBulkFile
                  isOpen={isImportModalOpen}
                  onClose={handleCloseImportModal}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <Box sx={{ padding: "15px" }}>
        {showProduct ? (
          <Addproduct
            onClose={() => setShowProduct(false)}
            onSave={handleSave}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        ) : isEditing && selectedProduct ? (
          <ProductEdit
            product={selectedProduct}
            onSave={handleSaveEdit}
            onCancel={handleCancelEdit}
            onFileChange={handleFileChange}
          />
        ) : currentView === "view" && selectedProduct ? (
          <Box style={{ width: "100%", height: "100vh" }}>
            <ProductView
              product={selectedProduct}
              onClose={() => {
                setCurrentView("list");
                setBreadcrumbText("Product listing");
              }}
              onSave={(updatedProduct: ModalFormData) => {
                setCurrentView("view");
                productRefetch();
              }}

            />
          </Box>
        ) : (
          <PheonixPaper>
            {tableData.length === 0 ? (
              <div style={{ width: "80%", position: "fixed" }}>
                <PheonixDataGrid
                  columns={[]}
                  rows={[]}
                  searchQuery={searchQuery}
                  onSearchQueryChange={handleSearchQueryChange}
                  rowCount={tableData.length}
                  showPagination={tableData.length > 0}
                  sx={{
                    "& .MuiDataGrid-footerContainer": {
                      visibility: "hidden",
                    },
                    "& .MuiButtonBase-root.MuiButton-root": {
                      color: "#49955E",
                    },
                  }}
                  customEmptyContent={
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          mb: 2,
                          textAlign: isSmallScreen ? "center" : "left",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: isSmallScreen ? "14px" : "16px",
                          }}
                        >
                          {TEXT_MESSAGES.CURRENT_PRODUCT}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 500,
                            fontSize: isSmallScreen ? "14px" : "16px",
                          }}
                        >
                          {tableData.length} {TEXT_MESSAGES.AVAILABLE_SERVICE}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                          width: "100%",
                          maxWidth: isLargeScreen ? "450px" : "100%",
                          minHeight: isMediumScreen ? "50vh" : "60vh",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            mb: 2,
                            fontSize: isSmallScreen ? "14px" : "16px",
                          }}
                        >
                          {TEXT_MESSAGES.NO_PRODUCT}
                        </Typography>
                        <PheonixButton
                          label={TEXT_MESSAGES.ADD_PRODUCT}
                          width={"auto"}
                          endIcon={
                            <OpenInNewIcon sx={{ color: "white", width: 23 }} />
                          }
                          variant="contained"
                          onClick={handleAddProduct}
                          sx={{
                            background: themestyle.colors.Primary2,
                            color: themestyle.colors.white,
                            textTransform: "none",
                            marginBottom: 2,
                            borderRadius: "16px",
                            width: isSmallScreen ? "90%" : "auto",
                            fontFamily: "Lexend",
                            "&:hover": {
                              background: themestyle.colors.Primary2,
                              color: themestyle.colors.white,
                            },
                          }}
                        ></PheonixButton>
                        <br></br>
                        <Box
                          component="img"
                          src={product}
                          alt="Product"
                          sx={{
                            width: isSmallScreen
                              ? "120px"
                              : isMediumScreen
                                ? "140px"
                                : "150px",
                            height: isSmallScreen
                              ? "130px"
                              : isMediumScreen
                                ? "150px"
                                : "161px",
                            maxWidth: "100%",
                          }}
                        />
                      </Box>
                    </Box>
                  }
                />
              </div>
            ) : (
              <div style={{ width: "100%", overflowX: "auto" }}>
                <div></div>
                <PheonixDataGrid
                  columns={columns}
                  pageSizeOptions={[5, 10, 20, 30]}
                  style={{ border: "0px", marginLeft: "20px" }}
                  rows={tableData}
                  isShownAlert={true}
                  searchQuery={searchQuery}
                  onSearchQueryChange={handleSearchQueryChange}
                  loading={dataLoading}
                  rowCount={tableCount}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  showPagination={currentView === "list"}
                  onPaginationModelChange={handlePaginationChange}
                  getRowId={(row) =>
                    row.id || `${paginationModel.page}-${row.serial_no}`
                  }
                  sx={{
                    "& .MuiButtonBase-root.MuiButton-root": {
                      color: "#49955E",
                    },
                    width: "auto",
                  }}
                />
              </div>
            )}
          </PheonixPaper>
        )}
        <PheonixPopup
          open={popup}
          onClose={handlePopupclose}
          onConfirm={() => { setPopup(false) }}
        />

        <PheonixSnackbar
          vertical="top"
          horizontal="right"
          open={snackbarOpen}
          onClose={() => {
            setSnackbarOpen(false);
          }}
          message={alertmessage}
          severity="success"
          backgroundColor={
            snackbarOpen
              ? snackbarType === "add"
                ? "#2E7D32"
                : snackbarType === "edit"
                ? "#EDF7ED"
                : "#D32F2F29"
              : "transparent"
          }
          color={
            snackbarOpen
              ? snackbarTypecolor === "add"
                ? "#FFFFFF"
                : snackbarTypecolor === "edit"
                ? "#1E4620"
                : "#663C00"
              : "transparent"
          }
          sx={{
            fontFamily: "Lexend",
            top: "170px",
            fontWeight: 500,
            fontSize: isSmallScreen ? "12px" : isMediumScreen ? "13px" : "14px",
            mt: isSmallScreen ? 17 : isMediumScreen ? 16 : 14,
            padding: isSmallScreen ? "5px 5px" : "8px 15px",
            width: isSmallScreen ? "50px" : isMediumScreen ? "230px" : "250px",
            maxWidth: isSmallScreen ? "60%" : "500px",
            minWidth: "150px",
            height: isSmallScreen ? "34px" : isMediumScreen ? "36px" : "28px",
            whiteSpace: "normal",
            wordWrap: "break-word",
            textAlign: "center",
            paddingTop: "3px", 
          }}
        />
      </Box>
    </div>
  );
};
export default Product;
